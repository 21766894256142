import React, { useEffect, useState } from 'react';
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    Button,
    TableRow,
    TableBody,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stack,
    TextField,
    IconButton,
    Box,
    Typography,
    Toolbar,
    Pagination,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import { addUdharData, fetchUdharOrderData } from '../../redux/actions/udharOrderActions';
import { fetchOrderData, triggerNotificationOnOrderAction } from '../../redux/actions/orderActions';
import { FormControl } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Udhar() {
    const columns = [
        { id: 'name', name: 'Customer Name' },
        { id: 'mobile', name: 'Mobile' },
        { id: 'udhar_amount', name: 'Amount' },
        { id: 'order_id', name: 'Order Id & Items' },
        { id: 'completion_date', name: 'Completion Date' },
        { id: 'actions', name: 'Actions' }
    ];

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const orders = useSelector((state) => state.order.orders);

    console.log("orders", orders);

    useEffect(() => {
        const fetchOrder = async () => {
            setLoading(true);
            let rsp = await dispatch(fetchOrderData(page, limit));
            console.log("rsp", rsp);
            let total = rsp?.data?.total;
            let pages = Math.ceil(total / Number(limit));
            setTotalPages(pages);
            // await dispatch(fetchCustomerData());
            setLoading(false);
        };
        fetchOrder();
    }, [dispatch]);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState('');
    const limit = 50;

    useEffect(() => {
        fetchUdharOrderData(page, limit);
    }, [page]);

    const handlePageChange = async (event, value) => {
        console.log("value", value);
        await setPage(value);
        let rsp = await dispatch(fetchUdharOrderData(value, limit));
    };

    useEffect(() => {
        const fetchUdharData = async () => {
            setLoading(true);
            let rsp = await dispatch(fetchUdharOrderData(page, limit, search));
            console.log("rsp", rsp);
            let total = rsp?.data?.total;
            let pages = Math.ceil(total / Number(limit));
            setTotalPages(pages);
            setLoading(false);
        };

        fetchUdharData();
    }, [dispatch]);

    const refreshButton = async () => {
        setLoading(true);
        await dispatch(fetchUdharOrderData(page, limit, search));
        setLoading(false);
    }

    const [openFilter, setOpenFilter] = useState(false);

    const handleOpenFilter = () => {
        setOpenFilter(true);
    }
    const handleCloseFilter = () => {
        setOpenFilter(false);
    }

    const handleSearch = async () => {
        setPage(1);
        setLoading(true);
        await dispatch(fetchUdharOrderData(1, limit, search));
        setLoading(false);
    };

    const handleClear = async () => {
        setSearch('');
        setPage(1);
        setLoading(true);
        await dispatch(fetchUdharOrderData(1, limit, ''));
        setLoading(false);
    };


    const data = useSelector((state) => state.udharOrder.udharOrders);
    const error = useSelector((state) => state.charge.error);
    console.log("Udhar Data", data);

    const [chargeName, setChargeName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [priceUnit, setPriceUnit] = useState('');
    const [open, setOpen] = useState(false);
    const [orderId, setOrdrId] = useState('');
    const [amount, setAmount] = useState('');


    const functionAdd = () => {
        openpopup();
    };

    const closepopup = () => {
        setOpen(false);
    };

    const openpopup = () => {
        setOpen(true);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const udharData = { order_id: orderId, udhar_amount: amount }
        console.log("inside submit", udharData);

        await dispatch(addUdharData(udharData));
        await dispatch(fetchUdharOrderData(page, limit, search));

        setOrdrId('');
        setAmount('');


    };

    const triggerNotificationOnOrderFn = async (orderId) => {
        const response = await dispatch(triggerNotificationOnOrderAction(orderId, {
            "channel": "call",
            "message_code": "udhar.remainder"
        }));
        console.log('response', response);
    }

    const navigate = useNavigate();

    const [language, setLanguage] = useState(() => {
        return localStorage.getItem('language') || 'en';
    });
    const headingObj = {
        en: 'Udhar',
        hi: 'उधार'
    }
    const addButton = {
        en: 'Add New Udhar',
        hi: 'नया उधार जोडे'
    }
    const call = {
        en: 'Send Reminder Call',
        hi: 'रिमाइंडर कॉल भेजें'
    }
    const view = {
        en: 'View',
        hi: 'आलोकन'
    }

    return (
        <div>
            <Box position="static" sx={{ borderRadius: 3, color: 'black' }}>
                <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: { xs: 'flex-start', md: 'space-between' },
                        width: '100%'
                    }}>
                        <Typography sx={{ 
                            fontSize: { xs: '20px', sm: '24px', md: '28px' }, 
                            mb: { xs: 2, md: 0 },
                            textAlign: {}
                            }}>
                            {headingObj[language]}
                        </Typography>

                        <Box sx={{
                            display: 'flex',
                            gap: { xs: 1, md: 0.5 },
                            flexDirection: { xs: 'column', md: 'row' },
                            justifyContent: { xs: 'center', md: 'flex-end' },
                            width: { xs: '100%', md: 'auto' },
                            alignItems: 'center'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: { xs: 'row', md: 'row' },
                                justifyContent: {xs: 'flex-start'}
                            }}>
                                <IconButton>
                                    <FilterAltIcon sx={{ fontSize: { xs: '25px', sm: '30px', md: '35px' } }} />
                                </IconButton>

                                <IconButton onClick={refreshButton}>
                                    <RefreshIcon sx={{ fontSize: { xs: '25px', sm: '30px', md: '35px' } }} />
                                </IconButton>
                            </Box>

                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: 'midnightblue',
                                    textTransform: 'none',
                                    fontSize: { xs: '12px', sm: '14px', md: '16px' },
                                    width: { xs: '100%', sm: 'auto' }
                                }}
                                onClick={functionAdd}
                            >
                                {addButton[language]}(+)
                            </Button>
                        </Box>
                    </Box>
                </Toolbar>
            </Box>


            <Paper sx={{
                margin: '1%',
                padding: '1%',
                width: {}
            }}>
                <div style={{ margin: '1%' }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow style={{ backgroundColor: 'midnightblue' }}>
                                    {columns.map((column) => (
                                        <TableCell key={column.id} style={{ color: 'white' }}>{column.name}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={columns.length} align="center">
                                            Loading...
                                        </TableCell>
                                    </TableRow>
                                ) : error ? (
                                    <TableRow>
                                        <TableCell colSpan={columns.length} align="center">
                                            Error fetching data
                                        </TableCell>
                                    </TableRow>
                                ) : data && data.data?.rows.length > 0 ? (
                                    data.data.rows.map((row) => (
                                        <TableRow key={row?.order_id}>
                                            <TableCell>{row?.associated_customer?.name}</TableCell>
                                            <TableCell>{row?.associated_customer?.mobile}</TableCell>
                                            <TableCell>{row?.udhar_amount}</TableCell>
                                            <TableCell>
                                                <Typography variant="body2">Order ID: {row?.order_id}</Typography>
                                                {row?.order_items?.map((item, index) => (
                                                    <Box key={index} sx={{ marginLeft: 2 }}>
                                                        <Typography variant="body2">Item {index + 1}: {item?.associated_order_product?.product_name || 'N/A'}</Typography>
                                                        {/*  {item.final_image && (
                                                            <img
                                                                src={item.final_image}
                                                                alt="Final product"
                                                                style={{ maxWidth: '100px', maxHeight: '100px', marginTop: '5px' }}
                                                            />
                                                        )} */}
                                                    </Box>
                                                ))}
                                            </TableCell>
                                            <TableCell>{new Date(row?.completion_date).toLocaleDateString()}</TableCell>
                                            <TableCell sx={{ gap: 2 }}>
                                                <Button variant='contained' style={{ backgroundColor: 'green', color: 'white', textTransform: 'none' }} onClick={() => triggerNotificationOnOrderFn(row.order_id)}>{call[language]}</Button>
                                                <Button variant='outlined' style={{ color: 'red', textTransform: 'none' }} onClick={() => navigate(`/order/view/${row.order_id}`)}>{view[language]}</Button>

                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={columns.length} align="center">
                                            No data available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Paper>
            <Dialog open={open} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Create Udhar</Typography>
                    </DialogTitle>
                    <IconButton onClick={closepopup} >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={2} margin={2}>
                            <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                                <TextField label="Order Id" value={orderId} onChange={e => setOrdrId(e.target.value)}></TextField>
                                <TextField label="Amount" value={amount} onChange={e => setAmount(e.target.value)}></TextField>
                            </Stack>
                            <Stack spacing={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 20, justifyContent: 'center' }} >
                                <Button variant='outlined' onClick={closepopup}>Cancel</Button>
                                <Button variant='contained' type='submit'>Submit</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5, }}>
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                />
            </Box>

        </div>
    );
}

export default Udhar;
