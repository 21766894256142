import { statusChange } from "../../api/index.js";
import * as actionTypes from "../actionTypes.js";

const initialState = {
    statusChanges: [],
    loading: false,
    error: null,
}

const statusChangeReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.STATUS_CHANGE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.STATUS_CHANGE_SUCCESS:
            console.log(state.statusChanges)
            state.statusChanges.data = state.statusChanges.data.map(statusChanges =>
                statusChanges.item_id === action.payload.item_id ? action.payload : statusChanges
            )
            return {
                ...state,
                statusChanges: state.statusChanges,
                loading: false,
                error: null,
            };
        case actionTypes.STATUS_CHANGE_FAILURE:
            return {
                ...state,
                loading: false,
                error: null,
            };
        default: 
            return state;
    }
};
export default statusChangeReducer;
