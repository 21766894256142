import * as actionTypes from "../actionTypes.js";

const initialState = {
    customers: [],
    loading: false,
    error: null,
    addCustomerLoading: false,
    addCustomerError: null,
};

const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CUSTOMER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_CUSTOMER_SUCCESS:
            return {
                ...state,
                customers: action.payload,
                loading: false,
                error: null,
            };
        case actionTypes.FETCH_CUSTOMER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.ADD_CUSTOMER_REQUEST:
            return {
                ...state,
                addCustomerLoading: true,
                addCustomerError: null,
            };
        case actionTypes.ADD_CUSTOMER_SUCCESS:
            state.customers.data.rows = [...state.customers.data.rows, action.payload]
            console.log(action.payload)
            return {
                ...state,
                customers: state.customers,
                addCustomerLoading: false,
                addCustomerError: null,
            };
        case actionTypes.ADD_CUSTOMER_FAILURE:
            return {
                ...state,
                addCustomerLoading: false,
                addCustomerError: action.payload,
            };
        case actionTypes.EDIT_CUSTOMER_REQUEST:
            return {
                ...state,
                editCustomerLoading: true,
                editCustomerError: null,
            };
        case actionTypes.EDIT_CUSTOMER_SUCCESS:
            state.customers.data.rows = state.customers.data.rows.map(customer =>
                customer.user_id === action.payload.user_id ? action.payload : customer
            )
            console.log(action.payload)
            return {
                ...state,
                customers: state.customers,
                editCustomerLoading: false,
                editCustomerError: null,
            };
        case actionTypes.EDIT_CUSTOMER_FAILURE:
            return {
                ...state,
                editCustomerLoading: false,
                editCustomerError: action.payload,
            };
        case actionTypes.DELETE_CUSTOMER_REQUEST:
            return {
                ...state,
                deleteCustomerLoading: true,
                deleteCustomerError: null,
            };
        case actionTypes.DELETE_CUSTOMER_SUCCESS:
            state.customers.data.rows = state.customers.data.rows.filter(customer => customer.user_id !== action.payload)
            console.log(state.customers)
            return {
                ...state,
                customers: state.customers,
                deleteCustomerLoading: false,
                deleteCustomerError: null,
            };
        case actionTypes.DELETE_CUSTOMER_FAILURE:
            return {
                ...state,
                deleteCustomerLoading: false,
                deleteCustomerError: action.payload,
            };
            case actionTypes.SEARCH_CUSTOMER_REQUEST:
                return {
                    ...state,
                    loading: true,
                    error: null,
                };
            case actionTypes.SEARCH_CUSTOMER_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    customers: action.payload, 
                };
            case actionTypes.SEARCH_CUSTOMER_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
        default:
            return state;
    }
};

export default customerReducer;
