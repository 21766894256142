import * as actionTypes from "../actionTypes.js";

const initialState = {
    orderDetails: [],
    loading: false,
    error: null,
}

const orderDetailsReduces = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_ORDER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.ADD_ORDER_DETAILS_SUCCESS:
            state.orders.data = [...state.orders.data, action.payload]
            return {
                ...state,
                orderDetails: state.orderDetails,
                loading: false,
                error: null,
            };
        case actionTypes.ADD_ORDER_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.CREATE_MULTIPLE_ITEMS_REQUEST: 
            return{
                ...state,
                loading: true,
                error: null,
            }
        case actionTypes.CREATE_MULTIPLE_ITEMS_SUCCESS:
            console.log("inside multiple reducer",state);
            state.orderDetails.data = [...state.orderDetails.data, action.payload]
            return{
                ...state,
                orderDetails: state.orderDetails,
                loading: false,
                error: null,
            }
        case actionTypes.CREATE_MULTIPLE_ITEMS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,

            }
        case actionTypes.EDIT_ORDER_DETAILS_REQUEST:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case actionTypes.EDIT_ORDER_DETAILS_SUCCESS:
            state.orderDetails.data = state.orderDetails.data.map(orderDetails =>
                orderDetails.order_id === action.payload.order_id ? action.payload : orderDetails
            )
            return {
                ...state,
                orderDetails: state.orders,
                loading: false,
                error: null,
            }

           
        case actionTypes.EDIT_ORDER_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.DELETE_ORDER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case actionTypes.DELETE_ORDER_DETAILS_SUCCESS:
            console.log("order Details inside delete",state.orderDetails.data )
            state.orderDetails.data = state.orderDetails.data.filter(orderDetails => orderDetails.item_id != action.payload);
            return {
                ...state,
                orderDetails: state.orderDetails,
                loading: false,
                error: null,
            };
        case actionTypes.DELETE_ORDER_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case actionTypes.MARK_ORDER_ITEM_MISTAKE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.MARK_ORDER_ITEM_MISTAKE_SUCCESS:
            return {
                ...state,
                loading: false,
                orderDetails: action.payload
            };
        case actionTypes.MARK_ORDER_ITEM_MISTAKE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};
export default orderDetailsReduces;
