import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const statusChangeRequest =() => ({
    type: actionTypes.STATUS_CHANGE_REQUEST,
});
export const statusChangeSuccess = (data) => ({
    type: actionTypes.STATUS_CHANGE_SUCCESS,
    payload: data
});
export const statusChangeFailure = (error) => ({
    type: actionTypes.STATUS_CHANGE_FAILURE,
    payload: error
});

export const statusChangeData = (orderData) => {
    return async (dispatch) => {
        dispatch(statusChangeRequest());
        try {
            console.log("testing",orderData.order_id);
            const response = await api.statusChange(orderData.order_id, orderData.item_id,orderData);
            console.log("response",response)
            const updatedItem = response.data;
            console.log("updated Item",updatedItem)
            dispatch(statusChangeSuccess(updatedItem));
            toast.success(updatedItem.message, {
                theme: 'light',
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return updatedItem;
        } catch (error) {
            dispatch(statusChangeFailure(error));
        }
    };
};
