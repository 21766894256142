import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const fetchProductRequest = () => ({
    type: actionTypes.FETCH_PRODUCT_REQUEST,
});

export const fetchProductSuccess = (data) => ({
    type: actionTypes.FETCH_PRODUCT_SUCCESS,
    payload: data,
});

export const fetchProductFailure = (error) => ({
    type: actionTypes.FETCH_PRODUCT_FAILURE,
    payload: error,
});

export const fetchProductData= (page, limit, search = '') => {
    return async (dispatch) => {
        dispatch(fetchProductRequest());
        try {
            const {data} = await api.products(page, limit, search);
            console.log("data inside product", data);
            dispatch(fetchProductSuccess(data));
            return data;
        }
        catch(error) {
            dispatch(fetchProductFailure(error));
        }
    };
};

export const addProductRequest = () => ({
    type: actionTypes.ADD_PRODUCT_REQUEST,
});

export const addProductSuccess = (product) => ({
    type:actionTypes.ADD_PRODUCT_SUCCESS,
    payload: product,
});

export const addProductFailure = (error) => ({
    type: actionTypes.ADD_PRODUCT_FAILURE,
    payload: error
});

export const addProductData = (productData) => {
    return async (dispatch) => {
        dispatch(addProductRequest());
        try {
            const {data} = await api.addProduct(productData);
            console('data-', data);
            dispatch(addProductSuccess(data));
        } catch (error) {
            dispatch(addProductFailure(error));
        }
    };
};

export const deleteProductRequest = () => ({
    type: actionTypes.DELETE_PRODUCT_REQUEST,
});
export const deleteProductSuccess = (id) => ({
    type: actionTypes.DELETE_PRODUCT_SUCCESS,
    payload: id,
});
export const deleteProductFailure = (error) => ({
    type: actionTypes.DELETE_PRODUCT_FAILURE,
    payload:error
});
export const deleteProductData = (id) => {
    return async (dispatch) => {
        dispatch(deleteProductRequest());
        try {
           const {data}= await api.deleteProduct(id);
           console.log(data)
            dispatch(deleteProductSuccess(id));
            toast.success("Product Deleted Successfully");
        } catch (error) {
            dispatch(deleteProductFailure(error));
            toast.error("failed to delete product");
        }

    };
};

export const editProductRequest = () => ({
    type: actionTypes.EDIT_PRODUCT_REQUEST,
});
export const editProductSuccess = (updatedItem) => ({
    type: actionTypes.EDIT_PRODUCT_SUCCESS,
    payload: updatedItem,
});
export const editProductFailure = (error) => ({
    type: actionTypes.EDIT_PRODUCT_FAILURE,
    payload: error,
});

export const editProductData = (productData) => {
    return async (dispatch) => {
        dispatch(editProductRequest());
        try{
            const response = await api.editProduct(productData.product_id,productData);
            const updatedItem = response.data;
            console.log(updatedItem);
            const successAction = editProductSuccess(updatedItem);
            dispatch(successAction);
            toast.success('Data updated successfully');
            return successAction;
        } catch (error) {
            dispatch(editProductFailure(error));
        }
    };
};


export const uploadProductImageRequest = () => ({
    type: actionTypes.UPLOAD_PRODUCTIMG_REQUEST,
});

export const uploadProductImageSuccess = (updatedItem) => ({
    type: actionTypes.UPLOAD_PRODUCTIMG_SUCCESS,
    payload: updatedItem,
});

export const uploadProductImageFailure = (error) => ({
    type: actionTypes.UPLOAD_PRODUCTIMG_FAILURE,
    payload: error,
});

export const uploadProductImageData = (formData) => {
    return async (dispatch) => {
        dispatch(uploadProductImageRequest());
        try {
            
            const response = await api.productImage(formData.get('product_id'),formData);
            const updatedItem = response.data;
            console.log(updatedItem);
            const successAction = uploadProductImageSuccess(updatedItem);
            dispatch(successAction);
            toast.success('Image Uploaded successfully');
            return successAction;
        } catch (error) {
            dispatch(uploadProductImageFailure(error));
            toast.error('Image upload failed');
        }
    };
};

export const fetchProductListRequest = () => ({
    type: actionTypes.FETCH_PRODUCTLIST_REQUEST,
});

export const fetchProductListSuccess = (data) => ({
    type: actionTypes.FETCH_PRODUCTLIST_SUCCESS,
    payload: data,
});

export const fetchProductListFailure = (error) => ({
    type: actionTypes.FETCH_PRODUCTLIST_FAILURE,
    payload: error,
});

export const fetchProductListData= (productData) => {
    return async (dispatch) => {
        dispatch(fetchProductListRequest());
        try {
            const {data} = await api.productList(productData);
            dispatch(fetchProductListSuccess(data));
        }
        catch(error) {
            dispatch(fetchProductListFailure(error));
        }
    };
};



