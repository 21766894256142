import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify'; 

export const fetchUdharOrderRequest = () => ({
    type: actionTypes.UDHAR_ORDER_REQUEST,
});
export const fetchUdharOrderSuccess = (data) => ({
    type : actionTypes.UDHAR_ORDER_SUCCESS,
    payload: data
});
export const fetchUdharOrderFailure = (error) => ({
    type: actionTypes.UDHAR_ORDER_FAILURE,
    payload: error,
});

export const fetchUdharOrderData = (page, limit,search= '') => {
    return async (dispatch) => {
        dispatch(fetchUdharOrderRequest());
        try{
            const {data} = await api.udharOrder(page, limit,search);
            dispatch(fetchUdharOrderSuccess(data));
            return data;

        } catch (error) {
            dispatch(fetchUdharOrderFailure(error));
        }
    };

};

export const addUdharRequest = () => ({
    type: actionTypes.MARK_UDHAR_REQUEST,
});
export const addUdharSuccess = (udhar) => ({
    type: actionTypes.MARK_UDHAR_SUCCESS,
    payload: udhar,
});
export const addUdharFailure = (error) => ({
    type: actionTypes.MARK_UDHAR_FAILURE,
    payload: error
});

export const addUdharData = (udharData) => {
    return async (dispatch) => {
        console.log("inside udhar mark")
        dispatch(addUdharRequest());
        try {
            const {data} = api.markUdhar(udharData.order_id, udharData);
            console.log('data=>', data.data);
            dispatch(addUdharSuccess(data));

        } catch (error) {
            dispatch(addUdharFailure(error));
        }
    }
}