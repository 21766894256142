import { AppBar, Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductData } from '../../redux/actions/productActions';
import { createMultipleOrderItemData } from '../../redux/actions/orderDetailsActions';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const CreateIndividualOrderPage = () => {
  const { order_id } = useParams();
  const location = useLocation();

  {/*Retrieve date from query parameters*/ }
  const queryParams = new URLSearchParams(location.search);
  const dateFromQuery = queryParams.get('date');
  const initialDate = dateFromQuery ? dayjs(dateFromQuery) : dayjs();

  {/* Fetch Operation */ }
  const products = useSelector((state) => state.product.products);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      const products = await dispatch(fetchProductData(0, 500));
      console.log('products', products);
      setLoading(false);
    };

    fetchProduct();
  }, [dispatch]);

  {/* Add functionality */ }
  const [data, setData] = useState([{
    product_id: "", quantity: "", completion_date: initialDate, width: "",
    height: "", comment: "", stage: "", description: "", order_id: order_id
  }]);

  const handleClick = () => {
    setData([...data, {
      product_id: "", quantity: "", completion_date: initialDate, width: "",
      height: "", comment: "", stage: "", description: "", order_id: order_id
    }]);
  };

  const handleChange = (e, i) => {
    const { name, value } = e.target;
    const onChangeval = [...data];
    onChangeval[i][name] = value;
    setData(onChangeval);
  };

  const handleDateChange = (newDate, i) => {
    const onChangeval = [...data];
    onChangeval[i]['completion_date'] = newDate;
    setData(onChangeval);
  };
  const navigate = useNavigate();

  const addOrderDetails = async () => {
    console.log("data inside order details", data);
    const newOrderDetails = data.map(order => ({
      ...order
    }));


    {/* const response = await dispatch(createMultipleOrderItemData(newOrderDetails)); */ }
    const response = await dispatch(createMultipleOrderItemData(data));
    console.log('response', response);
    navigate(`/order`);
  };

  return (
    <div>
      <h1>Order Items</h1>
      {products.data && data?.map((val, i) => {
        const selectedProduct = products?.data?.rows?.find(product => product.product_id === val.product_id);
        const isDimensionRequired = selectedProduct ? selectedProduct.is_dimension_required : false;

        return (
          <Box sx={{
            display: 'flex', flexDirection: 'column', gap: 2, padding: '20px'
            , backgroundColor: '#F8F4FF', borderRadius: '16px',
            marginBottom: 1, boxShadow: "0px 4px 8px rgba(0, 0.2, 0.2, 0.4)"
          }}>

            
            <Stack key={i} sx={{ display: 'flex', direction: 'column', gap: 2, marginTop: 2, marginBottom: 2 }}>
              <Stack spacing={{ display: 'flex', flexDirection: 'row', gap: 16, width: 'full' }}>
                <FormControl sx={{minWidth: '250px'}} >
                  <InputLabel id="demo-simple-select-label">Product Choose</InputLabel>
                  <Select labelId="demo-simple-select-label" id="demo-simple-select" name="product_id" value={val.product_id} onChange={(e) => handleChange(e, i)}>
                    {products && products.data.rows?.length > 0 ? (
                      products.data.rows.map((product) => (
                        <MenuItem key={product.product_id} value={product.product_id}>{product.product_name}</MenuItem>
                      ))
                    ) : (
                      <Typography variant="body1" color="initial"></Typography>
                    )}
                  </Select>
                </FormControl>
                <TextField label="Quantity" name="quantity" value={val.quantity} onChange={(e) => handleChange(e, i)} />
                {isDimensionRequired && (
                  <>
                  <TextField label="Width" name="width"  value={val.width} onChange={e => handleChange(e, i)} />
                  <TextField label="Height" name="height"  value={val.height} onChange={e => handleChange(e, i)} />
                  </>
                )}
                
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Completion Date"
                    value={val.completion_date}
                    onChange={(newDate) => handleDateChange(newDate, i)}
                    renderInput={(params) => <TextField {...params}  />}
                    
                  />
                </LocalizationProvider>
                <TextField label="comment" name="comment" value={val.comment}  onChange={e => handleChange(e, i)} />
              </Stack>

              <Stack spacing={{ display: 'flex', flexDirection: 'row', gap: 16, width: 'full' }}>
                
                
              </Stack>
            </Stack>
          </Box>
        );
      })}
      <Stack spacing={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, justifyContent: 'center' }}>
        <Button variant='outlined' onClick={handleClick}>Add New Order Item</Button>
        <Button variant='contained' sx={{ marginRight: 2 }} onClick={addOrderDetails}>Save</Button>
      </Stack>
    </div>
  );
};

export default CreateIndividualOrderPage;
