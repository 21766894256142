import { Box, Button, Stack, TextField, Typography, Select, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addOrderData, fetchOrderData } from '../../redux/actions/orderActions';
import { fetchCustomerData, searchCustomerData } from '../../redux/actions/customerActions';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'
import { toast } from 'react-toastify';

function CreateNewOrder() {

    {/*Fetch Operation*/ }
    const orders = useSelector((state) => state.order.orders);
    const customers = useSelector((state) => state.customer.customers);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchOrder = async () => {
            setLoading(true);
            await dispatch(fetchOrderData());
            await dispatch(fetchCustomerData());
            setLoading(false);
        };
        fetchOrder();
    }, [dispatch]);


    {/*Handling customer Data*/ }




    {/*Add functionality*/ }
    let completionDate = dayjs();
    const [orderNumber, setOrderNumber] = useState('');
    const [address, setAddress] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [amount, setAmount] = useState('');
    const [paidAmount, setPaidAmount] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('cash');
    const [fileNumber, setFileNumber] = useState('');
    const [heading, setheading] = useState('');
    const [date, setDate] = useState(completionDate);
    const [stage, setStage] = useState('');
    const [comment, setComment] = useState('');
    const [district, setDistrict] = useState('Mirzapur, Uttar Pradesh');
    const [isNewCustomer, setIsNewCustomer] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [mobileHelperText, setMobileHelperText] = useState('');
    const navigate = useNavigate();

    const checkMobileNumber = async () => {
        if (!/^\d{10}$/.test(mobileNumber)) {
            setMobileError('Mobile number must be 10 digits');
            setIsNewCustomer(false);
            return;
        }
        setMobileError('');

        const response = await dispatch(searchCustomerData(mobileNumber))
        console.log('existingCustomer', response.data.data.exists)

        const existingCustomer = response.data.data.exists;

        if (existingCustomer) {
            setIsNewCustomer(false);
            console.log('response', response.data.data);
            let stmt = `✅ Name: ${response.data.data.customer_name}`;
            if (response.data.data.isDefaulter) {
                stmt += `😡 Customer is defaulter, Please take necessary action.`;
            }
            if (response.data.data.paidAmountBypassAllowed) {
                stmt += `😕 Paid Amount Bypass Allowed`;
                setPaidAmount(-1);
            }
            setMobileHelperText(stmt);
        } else {
            setIsNewCustomer(true);
        }
    };

    const addOrderSubmit = async () => {
        console.log("inside add")
        const newOrder = { file_number: fileNumber, heading, completion_date: date, stage, mobile: mobileNumber, comment, name: customerName, amount, paid_amount: paidAmount, address, district, paymentMethod };

        const response = await dispatch(addOrderData(newOrder));
        console.log('response :-', response)

        const orderId = response?.data?.order_id;
        console.log('order_id :-', orderId)

        // const data = await dispatch(fetchOrderData());

        // console.log('data', data);
        setFileNumber('');
        setMobileNumber('');
        setheading('');
        setDate(dayjs());
        setStage('');
        setComment('');
        setCustomerName('');
        setAmount('');
        setPaidAmount('');

        const formattedDate = dayjs(date).format('YYYY-MM-DD');

        if(orderId !== undefined) {
            navigate(`/order/items/${orderId}/create?date=${formattedDate}`);   
        } else {
            console.log('Order Id not found');
            toast.error("Failed to add Order. Please contact admin");
        }
    };


    const [amounterror, setAmountError] = useState();
    const [paidAmountError, setPaidAmountError] = useState();
    const [requiredPaidAmount, setRequiredPaidAmount] = useState();

    const isFormValid = mobileNumber && orderNumber && amount && paidAmount && (!isNewCustomer || (customerName && address)) && !paidAmountError && !amounterror;

    const handlePaidAmountChange = (e) => {
        const paidAmount = parseFloat(e.target.value);
        setPaidAmount(paidAmount);
        if (paidAmount == -1) {
            setPaidAmountError('');
            setRequiredPaidAmount(`Warning: You bypass the Paid Amount. It will be added to your account if unclaimed. आप भुगतान की गई राशि को बायपास करते हैं। दावा न किए जाने पर इसे आपके खाते में जोड़ दिया जाएगा।`);
        } else {
            const minPaidAmount = Math.max(0.2 * amount, 50);

            if (paidAmount > amount) {
                setPaidAmountError('Paid amount should be less than or equal to the total amount.');
            } else if (amount <= 50 && paidAmount <= 50) {
                setPaidAmountError('Both amount and paid amount should be greater than 50.');
            } else if (paidAmount < minPaidAmount) {
                setPaidAmountError(`Paid amount should be at least ${minPaidAmount}.`);
            } else {
                setPaidAmountError('');
            }
        }
    };

    const handleAmountChange = (e) => {
        const totalAmount = parseFloat(e.target.value);
        setAmount(totalAmount);
        if (totalAmount == -1) {
            setAmountError('');
            setRequiredPaidAmount(`Warning: You bypass Total Amount. It will be added to your account if unclaimed. आप कुल राशि को बायपास करते हैं। दावा न किए जाने पर इसे आपके खाते में जोड़ दिया जाएगा।`);
        } else {
            const minPaidAmount = Math.max(0.2 * totalAmount, 50);
            setRequiredPaidAmount(`Minumum Required Paid Amount - Rs. ${minPaidAmount} /-`);
            if (totalAmount < 50) {
                setAmountError('totalAmount must be 50 or higher');
            } else {
                setAmountError('');
            }
        }
    };


    return (
        <div>
            <Box sx={{
                display: 'flex', flexDirection: 'column', gap: 2, padding: '20px'
                , backgroundColor: 'white', borderRadius: '16px', marginBottom: '20px', boxShadow: "0px 4px 8px rgba(0, 0.2, 0.2, 0.4)"
            }}>

                <Typography variant="h6" color="initial">Create New Order Entry</Typography>
                <Stack sx={{ display: 'flex', direction: 'column', gap: 2 }} >

                    <Stack spacing={{ display: 'flex', flexDirection: 'row', gap: 16, width: 'full' }} >

                        <TextField label="Customer Mobile Number" fullWidth value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} onBlur={checkMobileNumber} error={!!mobileError} helperText={mobileError || mobileHelperText} />
                        <TextField label="File Order Number" fullWidth value={orderNumber} onChange={(e) => setOrderNumber(e.target.value)} ></TextField>

                    </Stack>
                    {isNewCustomer && (
                        <>
                            <Stack spacing={{ display: 'flex', flexDirection: 'row', gap: 16, width: 'full' }}>
                                <TextField label="Customer Name" value={customerName} onChange={(e) => setCustomerName(e.target.value)} fullWidth />
                                <TextField label="address" value={address} onChange={(e) => setAddress(e.target.value)} fullWidth />
                                <TextField label="District" value={district} onChange={(e) => setDistrict(e.target.value)} fullWidth />

                            </Stack>
                        </>
                    )
                    }
                    <Stack spacing={{ display: 'flex', flexDirection: 'row', gap: 16, width: 'full' }} >
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DatePicker value={date} onChange={(newDate) => setDate(newDate)} />
                        </LocalizationProvider>

                        <TextField label="Amount" type='number' onChange={handleAmountChange} error={amounterror} helperText={amounterror}
                            required value={amount} />

                        <TextField label="Paid Amount" type='number' onChange={handlePaidAmountChange} error={paidAmountError} helperText={paidAmountError} required value={paidAmount} ></TextField>

                        <Select
                            label="Payment Method"
                            value={paymentMethod}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                            fullWidth
                        >
                            <MenuItem value="cash">Cash</MenuItem>
                            <MenuItem value="online">Online</MenuItem>
                        </Select>

                    </Stack>

                    <Stack spacing={{ display: 'flex', flexDirection: 'row', gap: 16, width: 'full' }} >
                        <p style={{ color: 'purple' }}>{requiredPaidAmount}</p>
                    </Stack>

                    <Stack spacing={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, justifyContent: 'center' }} >
                        <Button variant='contained' onClick={addOrderSubmit} disabled={!isFormValid}>Next (Create Order Entry) </Button>
                    </Stack>
                </Stack>
            </Box>
        </div>
    )
}

export default CreateNewOrder