import * as actionTypes from "../actionTypes.js";

const initialState = {
    searchOrders: [],
    loading: false,
    error: null,
}

const searchOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SEARCH_FILE_NUMBER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.SEARCH_FILE_NUMBER_SUCCESS:
            return {
                ...state,
                searchOrders: action.payload,
                loading: false,
                error: null,
            };
        case actionTypes.SEARCH_FILE_NUMBER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.SEARCH_MOBILE_NUMBER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.SEARCH_MOBILE_NUMBER_SUCCESS:
            return {
                ...state,
                searchOrders: action.payload,
                loading: false,
                error: null,
            };
        case actionTypes.SEARCH_MOBILE_NUMBER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };



        default:
            return state;
    }
};
export default searchOrderReducer;