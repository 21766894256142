import * as actionTypes from "../actionTypes.js";

const initialState = {
    closedOrders: [],
    loading: false,
    error: null,
}

const closedOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLOSED_ORDER_REQUEST: 
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.CLOSED_ORDER_SUCCESS:
            return {
                ...state,
                closedOrders: action.payload,
                loading: false,
                error: null,
            };
        case actionTypes.CLOSED_ORDER_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
export default closedOrderReducer;