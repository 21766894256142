import { Box, Dialog, DialogContent, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSingleOrderData } from '../../redux/actions/orderActions';
import { fetchProductListData } from '../../redux/actions/productActions';

const ViewIndividualOrderPage = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(params.child);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const dispatch = useDispatch();

  const orders = useSelector((state) => state.order.orders);
  const products = useSelector((state) => state.product.products);

  useEffect(() => {
    setId(params.child);
  }, [params.child]);

  useEffect(() => {
    const fetchSingleOrder = async () => {
      setLoading(true);
      const orderid = { order_id: id };
      await dispatch(getSingleOrderData(orderid));
      await dispatch(fetchProductListData());
      setLoading(false);
    };

    fetchSingleOrder();
  }, [dispatch, id]);

  const mobileNumber = orders?.data?.associated_customer?.mobile || '';
  const orderNumber = orders?.data?.order_id || '';
  const completionDate = orders?.data?.completion_date || '';
  const orderItems = orders?.data?.order_items || [];

  const getProductName = (productId) => {
    const product = products?.data?.find((product) => product.product_id === productId);
    return product ? product?.product_name : 'Unknown Product';
  };

  const handleClickOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <div>
      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2, textAlign: 'start' }}>
        Order Details
      </Typography>

      <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, boxShadow: "0px 4px 8px rgba(0, 0.2, 0.2, 0.4)", padding: '20px', borderRadius: '16px', marginBottom: '20px', background: 'white' }}>
          <Typography variant='span' sx={{ textAlign: 'right', fontSize: '0.775rem', color: 'gray' }}>Order Id :-{id}</Typography>
          <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%' }}>
            <Box sx={{ width: '50%', textAlign: 'start' }}>
              <Typography variant="caption" sx={{ color: 'text.secondary', textAlign: 'start' }}>
                Customer Mobile Number
              </Typography>
              <Typography variant="body1" sx={{ border: '1px solid', borderColor: 'divider', padding: '8px', borderRadius: '4px', textAlign: 'start', }}>
                {mobileNumber}
              </Typography>
            </Box>
            <Box sx={{ width: '50%', textAlign: 'start' }}>
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                Order Number
              </Typography>
              <Typography variant="body1" sx={{ border: '1px solid', borderColor: 'divider', padding: '8px', borderRadius: '4px', textAlign: 'start', }}>
                {orderNumber}
              </Typography>
            </Box>
          </Stack>
          <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%', mt: 2 }}>
            <Box sx={{ width: '50%', textAlign: 'start' }}>
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                Completion Date
              </Typography>
              <Typography variant="body1" sx={{ border: '1px solid', borderColor: 'divider', padding: '8px', borderRadius: '4px', textAlign: 'start', }}>
                {completionDate}
              </Typography>
            </Box>
            <Box sx={{ width: '50%', textAlign: 'start' }}>
              <Typography variant="caption" sx={{ color: 'text.secondary', textAlign: 'start' }}>
                Amount
              </Typography>
              <Typography variant="body1" sx={{ border: '1px solid', borderColor: 'divider', padding: '8px', borderRadius: '4px', textAlign: 'start', }}>
                50
              </Typography>
            </Box>
          </Stack>
        </Box>

        <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: 8, textAlign: 'start' }}>
          Order Items Details
        </Typography>

        {orderItems && orderItems.length > 0 ? (
          orderItems.map((item, index) => (
            <Box key={index} sx={{
              display: 'flex', flexDirection: 'column', gap: 2,
              boxShadow: "0px 4px 8px rgba(0, 0.2, 0.2, 0.4)", padding: '20px', borderRadius: '16px', marginBottom: '20px', background: 'white'
            }}>
              <Typography variant='span' sx={{ textAlign: 'right', fontSize: '0.775rem', color: 'gray' }}>Item Id :-{item.item_id}</Typography>
              <Stack direction="row" spacing={2}>
                {/* Image Box */}
                <Box sx={{ width: '200px', height: '200px', flexShrink: 0 }} onClick={() => handleClickOpen(item.final_image)}>
                  {item.final_image ? (
                    <img src={item.final_image} alt="No image" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px', cursor: 'pointer' }} />
                  ) : (
                    <Box sx={{ width: '100%', height: '100%' }}>Image Not Found</Box>
                  )}
                </Box>

                {/* Content Box */}
                <Box sx={{ flex: 1 }}>
                  <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: 'full' }}>
                    <Box sx={{ flex: 1, textAlign: 'start' }}>
                      <Typography variant="caption">product name</Typography>
                      <Typography variant="body1" sx={{ border: '1px solid', borderColor: 'divider', padding: '8px', borderRadius: '4px', textAlign: 'start' }}>
                        {getProductName(item.product_id) || '-'}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1, textAlign: 'start' }}>
                      <Typography variant="caption">quantity</Typography>
                      <Typography variant="body1" sx={{ border: '1px solid', borderColor: 'divider', padding: '8px', borderRadius: '4px', textAlign: 'start' }}>
                        {item.quantity || '-'}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1, textAlign: 'start', height: 'full' }}>
                      <Typography variant="caption">completion date</Typography>
                      <Typography variant="body1" sx={{ border: '1px solid', borderColor: 'divider', padding: '8px', borderRadius: '4px', textAlign: 'start' }}>
                        {item.completion_date || '-'}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: 'full' }}>
                    <Box sx={{ flex: 1, textAlign: 'start' }}>
                      <Typography variant="caption">width</Typography>
                      <Typography variant="body1" sx={{ border: '1px solid', borderColor: 'divider', padding: '8px', borderRadius: '4px', textAlign: 'start' }}>
                        {item.width}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1, textAlign: 'start' }}>
                      <Typography variant="caption">height</Typography>
                      <Typography variant="body1" sx={{ border: '1px solid', borderColor: 'divider', padding: '8px', borderRadius: '4px', textAlign: 'start' }}>
                        {item.height}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: 'full' }}>
                    <Box sx={{ flex: 1, textAlign: 'start' }}>
                      <Typography variant="caption">any comments</Typography>
                      <Typography variant="body1" sx={{ border: '1px solid', borderColor: 'divider', padding: '8px', borderRadius: '4px', textAlign: 'start' }}>
                        {item.comment}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          ))
        ) : (
          <Typography variant="body1" sx={{ textAlign: 'start', color: 'gray' }}>
            No order items found.
          </Typography>
        )}
      </Stack>

      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogContent>
          {selectedImage && (
            <img src={selectedImage} alt="Order Item" style={{ width: '100%', height: 'auto' }} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewIndividualOrderPage;
