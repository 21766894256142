import * as actionTypes from "../actionTypes.js";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const chargeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case actionTypes.FETCH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CREATE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.CREATE_DATA_SUCCESS:
      console.log("step3");
      console.log("----");
      console.log(state.data);
      console.log(action.payload);
      state.data.data.rows=[...state.data.data.rows, action.payload]
      
      return {
        ...state,
        loading: false,
        data: state.data
      };
    case actionTypes.CREATE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case actionTypes.UPDATE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.UPDATE_DATA_SUCCESS:
      console.log("step3");
      console.log("----");
      console.log(state.data);
      console.log(action.payload);
      state.data.data.rows= state.data.data.rows.map(item =>
        item.charge_id === action.payload.charge_id ? action.payload : item
      )
      
      return {
        ...state,
        loading: false,
        data: state.data
      };
    case actionTypes.UPDATE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case actionTypes.DELETE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.DELETE_DATA_SUCCESS:
      state.data.data.rows = state.data.data.rows.filter(item => item.charge_id !== action.payload)
      return {
        ...state,
        loading: false,
        data: state.data
      };
    case actionTypes.DELETE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default chargeReducer;
