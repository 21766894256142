import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const fetchCustomerRequest = () => ({
  type: actionTypes.FETCH_CUSTOMER_REQUEST,
});

export const fetchCustomerSuccess = (data) => ({
  type: actionTypes.FETCH_CUSTOMER_SUCCESS,
  payload: data,
});

export const fetchCustomerFailure = (error) => ({
  type: actionTypes.FETCH_CUSTOMER_FAILURE,
  payload: error,
});

export const fetchCustomerData = (page, limit , search = '') => {
  return async (dispatch) => {
    dispatch(fetchCustomerRequest());
    try {
      const { data } = await api.customers(page, limit, search);
      dispatch(fetchCustomerSuccess(data));
      return data;
    } catch (error) {
      dispatch(fetchCustomerFailure(error));
    }
  };
};

export const addCustomerRequest = () => ({
  type: actionTypes.ADD_CUSTOMER_REQUEST,
});

export const addCustomerSuccess = (customer) => ({
  type: actionTypes.ADD_CUSTOMER_SUCCESS,
  payload: customer,
});

export const addCustomerFailure = (error) => ({
  type: actionTypes.ADD_CUSTOMER_FAILURE,
  payload: error,
});

export const addCustomerData = (customerData) => {
 return async (dispatch) => {
    dispatch(addCustomerRequest()); 
    try {
      const { data } = await api.addCustomer(customerData);
      console.log(data);
      dispatch(addCustomerSuccess(data));
      return data;
      
    } catch (error) {
      dispatch(addCustomerFailure(error));
    }
  };
};


export const editCustomerRequest = () => ({
  type: actionTypes.EDIT_CUSTOMER_REQUEST,
});

export const editCustomerSuccess = (updatedCustomer) => ({
  type: actionTypes.EDIT_CUSTOMER_SUCCESS,
  payload: updatedCustomer,
});

export const editCustomerFailure = (error) => ({
  type: actionTypes.EDIT_CUSTOMER_FAILURE,
  payload: error,
});

export const editCustomerData = (customerData) => {
  return async (dispatch) => {
    dispatch(editCustomerRequest());
    try {
      const response = await api.editCustomer(customerData.user_id, customerData);
      const updatedCustomer = response.customerData; 
      console.log(updatedCustomer)
      if (updatedCustomer) {
        const successAction = editCustomerSuccess(updatedCustomer);
        dispatch(successAction);
        toast.success('Customer updated successfully');
        return successAction;
      } else {
        throw new Error('No customer data returned');
      }
    }catch (error) {
      const failureAction = editCustomerFailure(error.message);
      dispatch(failureAction);
    
      return failureAction;
    }
  };
};
export const deleteCustomerRequest = () => ({
  type: actionTypes.DELETE_CUSTOMER_REQUEST,
});

export const deleteCustomerSuccess = (id) => ({
  type: actionTypes.DELETE_CUSTOMER_SUCCESS,
  payload: id,
});

export const deleteCustomerFailure = (error) => ({
  type: actionTypes.DELETE_CUSTOMER_FAILURE,
  payload: error,
});
export const deleteCustomerData = (id) => {
  return async (dispatch) => {
    dispatch(deleteCustomerRequest());
    try {
      const data = await api.deleteCustomer(id);
      dispatch(deleteCustomerSuccess(id));
      return data;
    } catch (error) {
      dispatch(deleteCustomerFailure(error));
      toast.error("Failed to delete customer");
    }
  };
};

export const searchCustomerRequest = () => ({
  type: actionTypes.SEARCH_CUSTOMER_REQUEST,
});

export const searchCustomerSuccess = (data) => ({
  type: actionTypes.SEARCH_CUSTOMER_SUCCESS,
  payload: data,
})

export const searchCustomerFailure = (error) => ({
  type: actionTypes.SEARCH_CUSTOMER_FAILURE,
  payload: error,
});

export const searchCustomerData = (mobileNumber) => {
  return async (dispatch) => {
    dispatch(searchCustomerRequest());
    try {
      const response = await api.searchCustomer(mobileNumber);
      dispatch(searchCustomerSuccess(response));
      return response;
    } catch (error) {
        dispatch(searchCustomerFailure(error));
    }
  };
};