import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from "../components/navigation/Navigation";
import AuthPage from '../views/authPage/AuthPage';

import PublicRoutes from './publicRoutes';
import ProtectedRoutes from './protectedRoutes';
import OrderPage from '../views/orderPage/OrderPage';
import CreateIndividualOrderPage from '../views/orderPage/CreateIndividualOrderPage';
import EditIndividualOrderPage from '../views/orderPage/EditIndividualOrderPage';
import ViewIndividualOrderPage from '../views/orderPage/ViewIndividualOrderPage';
import CustomerPage from '../views/customerPage/CustomerPage';
import Dashboard from '../views/dashboard/Dashboard';
import ChargesCategory from '../views/charges/ChargesCategory';
import AddNewCharges from '../views/charges/AddNewCharges';
import Products from '../views/productPage/Products';
import CompleteOrder from '../views/completeOrders/CompleteOrder';
import SearchOrder from '../views/searchOrder/SearchOrder';
import Udhar from '../views/udhar/Udhar';
import CreateNewOrder from '../views/orderPage/CreateNewOrder';
import CloseOrder from '../views/closeOrders/CloseOrder';
import Profile from '../components/menus/Profile';
import SearchByFileNumber from '../views/searchOrder/SearchByFileNumber';


const MainRouter = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route path='/auth' element={<AuthPage />} />
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route path='/' element={<Navigation />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />

              <Route path='order'>
                <Route path='' element={<OrderPage />} />
                <Route path='create' element={<CreateNewOrder />} />
                <Route path='create123' element={<CreateIndividualOrderPage />} />
                <Route path='view/:child' element={<ViewIndividualOrderPage />} />
                <Route path='edit/:child' element={<EditIndividualOrderPage />} />

                <Route path='items/:order_id' element={<CreateIndividualOrderPage />} />
                <Route path='items/:order_id/create' element={<CreateIndividualOrderPage />} />
                <Route path='items/:order_id/edit/:item_id' element={<CreateIndividualOrderPage />} />
              </Route>
              <Route path="/search-order">
                <Route path='' element={<SearchOrder />} />
                <Route path='view/:child' element={< SearchByFileNumber />} />

                <Route path='items/:file_number' element={<SearchByFileNumber />} />
              </Route>

              <Route path='customer'>
                <Route path='' element={<CustomerPage />} />
              </Route>

              <Route path="/charges/" element={<ChargesCategory />}>
                <Route path="new-charges" element={<AddNewCharges />} />
              </Route>

              <Route path="/product" element={<Products />} />
              <Route path="/complete" element={<CompleteOrder />} />


              <Route path="/udhar" element={<Udhar />} />
              <Route path="/closed" element={<CloseOrder />} />
              <Route path="/profile" element={<Profile />} />


            </Route>
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default MainRouter;