import * as actionTypes from "../actionTypes.js";

// Function to check if the user is authenticated by looking for a token in localStorage
const isUserAuthenticated = () => {
  const token = localStorage.getItem("access_token");
  return (token + "").length > 20 ? true : false;
};

// Function to get the current user's token from localStorage
const getCurrentUser = () => {
  const token = localStorage.getItem("user");
  return (token + "").length > 20 ? JSON.parse(token) : null;
};

const clearLocalStorage = () => {
  localStorage.clear();
};

const initialState = {
  isAuthenticated: isUserAuthenticated(),
  user: getCurrentUser(),
  showOtpInput: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_OTP_INPUT:
      return {
        ...state,
        showOtpInput: action.payload.showOtpInput,
        isAuthenticated: false,
        user: null,
      };
    case actionTypes.LOG_IN:
      console.log(action.payload);
      localStorage.setItem("user", JSON.stringify(action.payload.user_info));
      localStorage.setItem("access_token", JSON.stringify(action.payload.access_token.token));
      localStorage.setItem("refresh_token", JSON.stringify(action.payload.refresh_token));
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case actionTypes.REGISTER:
      localStorage.setItem("user", JSON.stringify(action.payload.user_info));
      localStorage.setItem("access_token", JSON.stringify(action.payload.access_token.token));
      localStorage.setItem("refresh_token", JSON.stringify(action.payload.refresh_token));
      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: true
      };
    case actionTypes.FETCH_USER_DATA_SUCCESS: 
    return {
      ...state,
      user: action.payload,
      loading: false,
      error: null,
    };
    case actionTypes.FETCH_USER_DETAILS_SUCCESS:
      return{
        ...state,
        user: action.payload,
        loading: false,
        error: null,
      };

    case actionTypes.LOG_OUT:
      localStorage.clear();
      return initialState;

    default:
      return state;
  }
};

export default authReducer;