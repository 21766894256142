import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';


export const fetchOrderCountRequest = () => ({
    type: actionTypes.FETCH_ORDER_COUNT_REQUEST,
});

export const fetchOrderCountSuccess = (data) => ({
    type: actionTypes.FETCH_ORDER_COUNT_SUCCESS,
    payload: data,
});

export const fetchOrderCountFailure = (error) => ({
    type: actionTypes.FETCH_ORDER_COUNT_FAILURE,
    payload: error,
});

// Thunk Middleware

export const fetchOrderCountData = (formData) => {
    return async (dispatch) => {
        dispatch(fetchOrderCountRequest());
        try {
            const {data} = await api.orderCount(formData)
            dispatch(fetchOrderCountSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchOrderCountFailure(error));
        }
    }
}
