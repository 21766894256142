import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify'; 

export const fetchCompletedOrderRequest = () => ({
    type: actionTypes.COMPLETED_ORDER_REQUEST,
});
export const fetchCompletedOrderSuccess = (data) => ({
    type : actionTypes.COMPLETED_ORDER_SUCCESS,
    payload: data
});
export const fetchCompletedOrderFailure = (error) => ({
    type: actionTypes.COMPLETED_ORDER_FAILURE,
    payload: error,
});

export const fetchCompletedOrderData = (page, limit) => {
    return async (dispatch) => {
        dispatch(fetchCompletedOrderRequest());
        try{
            const {data} = await api.completedorder(page, limit);
            dispatch(fetchCompletedOrderSuccess(data));
            return data;

        } catch (error) {
            dispatch(fetchCompletedOrderFailure(error));
        }
    };

};