import { AppBar, Avatar, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Toolbar, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RefreshIcon from '@mui/icons-material/Refresh';

function Profile() {
    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <Box position="static" sx={{ borderRadius: 3, color: 'black' }}>
                    <Toolbar sx={{ borderRadius: 2 }}>
                        <Typography sx={{ fontSize: '28px' }}>My Profile</Typography>

                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ display: { xs: 'none', md: 'flex', gap: 10 } }}>
                            <RefreshIcon sx={{ fontSize: '35px' }} />
                        </Box>
                    </Toolbar>
                </Box>
                <Box sx={{display: 'flex' , justifyContent: 'center'}}>
                    <Avatar sx={{ backgroundColor: "#004182", width: 200, height: 185 }}>
                        <Typography color="white"></Typography>
                    </Avatar>
                    
                </Box>
                <Typography variant='h5' sx={{marginTop: 2}}>Welcome, Team Member</Typography>
                <Typography variant="h5" color="initial">Your Account is managed by Awesome Innovators Admin</Typography>


                {/* <Stack sx={{display: 'flex', alignItems: 'flex-start'}}>
                    <Typography variant="h5" color="initial">Basic Details</Typography>
                </Stack>
                <Box  sx={{ display: 'flex', flexDirection: 'column', gap: 2, 
                     border: '2px solid black', padding: '20px', 
                     borderRadius: '16px', marginBottom: '20px', marginTop: 2}}>
                        <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: 'full' }}>
                            <TextField label="Full Name" fullWidth></TextField>
                        </Stack>
                        <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: 'full' }}>
                            <TextField label="Address" fullWidth></TextField>
                        </Stack>
                        <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: 'full' }}>
                            <TextField label="District" fullWidth></TextField>
                            <TextField label="State" fullWidth></TextField>
                        </Stack>
                        <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}> 
                            <Typography></Typography>
                            <Button variant='contained' sx={{alignItems: 'end'}}>Save</Button>
                        </Stack>
                </Box>
                <Stack sx={{display: 'flex', alignItems: 'flex-start'}}>
                    <Typography variant="h5" color="initial">Contact Details</Typography>
                </Stack>
                <Box  sx={{ display: 'flex', flexDirection: 'column', gap: 2, 
                     border: '2px solid black', padding: '20px', 
                     borderRadius: '16px', marginBottom: '20px', marginTop: 2}}>
                        <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: 'full' }}>
                            <TextField label="Mobile Number" fullWidth></TextField>
                            <TextField label=" Emergency Contact Number (Any Family Member)" fullWidth></TextField>
                        </Stack>
                        
                       
                        <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}> 
                            <Typography></Typography>
                            <Button variant='contained' sx={{alignItems: 'end'}}>Save</Button>
                        </Stack>
                </Box> */}
            </Box>
        </div>
    )
}

export default Profile