import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const searchByFileNumberRequest = () => ({
    type: actionTypes.SEARCH_FILE_NUMBER_REQUEST,
});

export const searchByFileNumberSuccess = (data) => ({
    type: actionTypes.SEARCH_FILE_NUMBER_SUCCESS,
    payload: data
});

export const searchByFileNumberFailure = (error) => ({
    type: actionTypes.SEARCH_FILE_NUMBER_FAILURE,
    payload: error
});

export const searchByFileNumberData = (orderData) => {
    return async (dispatch) => {
        dispatch(searchByFileNumberRequest());
        try{
            const response = await api.searchOrderByFileNumber(orderData);
            dispatch(searchByFileNumberSuccess(response));
            return response;

        } catch (error) {
            dispatch(searchByFileNumberFailure(error));
        }
    
    };
};

export const searchByMobileNumberRequest = () => ({
    type: actionTypes.SEARCH_MOBILE_NUMBER_REQUEST,
});

export const searchByMobileNumberSuccess = (data) => ({
    type: actionTypes.SEARCH_MOBILE_NUMBER_SUCCESS,
    payload: data
});

export const searchByMobileNumberFailure = (error) => ({
    type: actionTypes.SEARCH_MOBILE_NUMBER_FAILURE,
    payload: error
});

export const searchByMobileNumberData = (orderData) => {
    return async (dispatch) => {
        dispatch(searchByMobileNumberRequest());
        try{
            const response = await api.searchOrderByMobileNumber(orderData);
            dispatch(searchByMobileNumberSuccess(response));
            return response;

        } catch (error) {
            dispatch(searchByMobileNumberFailure(error));
        }
    
    };
};