import { AppBar, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Pagination, Select, Stack, TextField, Toolbar, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerData } from '../../redux/actions/customerActions';
import { fetchCompletedOrderData } from '../../redux/actions/completedOrderActions';
import RefreshIcon from '@mui/icons-material/Refresh';
import { statusChangeData } from '../../redux/actions/statusChangeActions';
import { triggerNotificationOnOrderAction, markMistakeOnOrderAction, uploadOrderImage } from '../../redux/actions/orderActions';
import NoDataImage from "../../assets/images/no_data.jpg";
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';


const CompleteOrder = () => {
  {/*Fetch Operation*/ }
  const completedOrders = useSelector((state) => state.completedOrder.completedOrders);
  console.log('completedorders', completedOrders);
  const customers = useSelector((state) => state.customer.customers);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 50;

  useEffect(() => {
    fetchCompletedOrderData(page, limit);
  }, [page]);

  const handlePageChange = async (event, value) => {
    console.log("value", value);
    await setPage(value);
    let rsp = await dispatch(fetchCompletedOrderData(value, limit));
  };

  useEffect(() => {
    const fetchCompletedOrder = async () => {
      setLoading(true);
      let rsp = await dispatch(fetchCompletedOrderData(page, limit));
      let total = rsp?.data?.total;
      let pages = Math.ceil(total / Number(limit));
      setTotalPages(pages);

      setLoading(false);
    };
    fetchCompletedOrder();
  }, [dispatch]);


  const refreshButton = async () => {
    setLoading(true);
    await dispatch(fetchCompletedOrderData(page, limit));

    setLoading(false);
  }

  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('language') || 'en';
  });
  const headingObj = {
    en: 'Completed Orders',
    hi: 'संपूर्ण ऑर्डर'
  }
  const finalImage = {
    en: 'Final Image',
    hi: 'अंतिम चित्र'
  }
  const mistake = {
    en: 'Mistake',
    hi: 'त्रुटि'
  }
  const call = {
    en: 'Send Autometic Call',
    hi: 'आटोमेटिक कॉल करे'
  }


  const handleUpdateStage = async (newStage, itemId, order_id) => {
    const newStages = { stage: newStage, item_id: itemId, order_id: order_id }
    await dispatch(statusChangeData(newStages));
    refreshButton();
  }

  const markMistakeOnOrderFn = async (orderId) => {
    const response = await dispatch(markMistakeOnOrderAction(orderId, {}));
    console.log('response', response);
    refreshButton();
  }

  const triggerNotificationOnOrderFn = async (orderId) => {
    const response = await dispatch(triggerNotificationOnOrderAction(orderId, {
      "channel": "call",
      "message_code": "udhar.remainder"
    }));
    console.log('response', response);
  }

  {/*Handle Image*/ }

  const inputRef = useRef(null);
  const [imgItemId, setImgItemId] = useState('');
  const [file, setFile] = useState(null);
  const [imgOrderId, setImgOrderId] = useState('');

  const handleImageChange = useCallback((event) => {
    event.preventDefault();
    const selectedFile = event.target.files[0];
    console.log('filename', selectedFile);
    setFile(selectedFile);
  }, []);
  const handleButtonClick = (orderId, itemId) => {
    setImgOrderId(orderId);
    setImgItemId(itemId);
    console.log(imgOrderId);
    console.log(imgOrderId);
    if (inputRef.current) {
      inputRef.current.click();
    } else {
      console.error("inputRef is null");
    }
  };
  useEffect(() => {
    if (file && imgOrderId && imgItemId) {
      uploadFile(file);
    }
  }, [file, imgOrderId, imgItemId]);

  const uploadFile = useCallback(async (data) => {
    const formData = new FormData();
    formData.append('final_image', data);
    formData.append('item_id', imgItemId);
    formData.append('order_id', imgOrderId);
    await dispatch(uploadOrderImage(formData));

  }, [dispatch, imgItemId, imgOrderId]);

  // Utility function to generate a random color
  const getRandomColor = () => {
    const getLightValue = () => Math.floor(Math.random() * 128) + 128; // Ensure value is between 128 and 255
    const r = getLightValue();
    const g = getLightValue();
    const b = getLightValue();
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };


  return (
    <div>
      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Box position="static" sx={{ borderRadius: 3, color: 'black' }}>
            <Toolbar sx={{ borderRadius: 2 }}>
              <Typography sx={{ fontSize: '28px' }}>{headingObj[language]}</Typography>

              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: 'none', md: 'flex', gap: 10 } }}>


                <IconButton onClick={refreshButton}>
                  <RefreshIcon sx={{ fontSize: '35px' }} />
                </IconButton>

              </Box>
            </Toolbar>
          </Box>
        </Box>
        <Box>
          <Grid container style={{ marginTop: "2px" }}>
            {completedOrders && completedOrders.data && completedOrders.data.rows.length > 0 ? (
              completedOrders.data.rows.map((order, index) => (
                <Grid item xs={12} md={6} lg={6} key={index} sx={{ padding: '8px' }}>
                  <Card sx={{ p: 2, boxShadow: '5px 5px rgba(0, 0, 0, 0.19)', backgroundColor: '#E8E8E8', height: '100%', borderRadius: '15px', border: order.mistake_marked ? '1px solid red' : 'none' }}>
                    {/* Order ID */}
                    <Stack spacing={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%'
                    }}>
                      <Typography variant="body2" color="initial" sx={{ fontWeight: 600, fontSize: { xs: '0.725rem' } }}>Order #{order.order_id}</Typography>
                    </Stack>
                    {/* Customer Name and Phone */}
                    <Stack sx={{
                      display: 'flex',
                      flexDirection: { xs: 'row', sm: 'row' },
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      mt: 1,
                      gap: { xs: '2px', sm: '6px' }, // Reduced gap

                    }}>
                      <Typography sx={{ fontSize: { xs: '0.9rem', lg: '1.7rem' } }}>{order.associated_customer.name}</Typography>
                      <IconButton sx={{ padding: 0 }}>
                        <LocalPhoneRoundedIcon fontSize="small" />
                        <Typography marginLeft={1} sx={{ color: 'black', fontSize: { xs: '0.9rem', lg: '1.2rem' } }}>{order.associated_customer?.mobile}</Typography>
                      </IconButton>
                    </Stack>

                    <Typography variant="body2" align='left' sx={{ color: 'gray' }}>Details</Typography>

                    <Grid container sx={{ marginTop: '5px', gap: '10px' }}>
                      {order.order_items && order.order_items.map((item, index) => (
                        <Grid container item xs={12} key={index} sx={{
                          borderRadius: '10px',
                          padding: '10px',
                          border: '1px solid lightgray',
                          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                          display: 'flex',
                          flexDirection: { xs: 'row' }, // Column for mobile, row for large screens
                          alignItems: 'flex-start'
                        }}>

                          {/* Product Name and Quantity */}
                          <Grid item xs={12} lg={4} sx={{
                            display: 'flex',
                            flexDirection: { xs: 'row', lg: 'column' },
                            justifyContent: 'space-between', // Align name and quantity on opposite sides
                            marginBottom: { xs: '5px', lg: '0' } // Margin for mobile
                          }}>
                            <Typography variant="subtitle2" sx={{
                              fontWeight: 600,
                              fontSize: { xs: '0.8rem', lg: '1rem' } // Font adjustment for mobile
                            }}>
                              {item.associated_order_product?.product_name || "Product's name is not available"}
                            </Typography>
                            <Typography variant="caption" sx={{
                              color: 'gray',
                              fontSize: { xs: '0.75rem', lg: '0.9rem' }
                            }}>
                              Qty: {item.quantity}
                            </Typography>
                          </Grid>

                          {/* Display Message and Employee Details */}
                          <Grid item xs={12} lg={4} sx={{
                            display: 'flex',
                            flexDirection: { xs: 'row', lg: 'column' }, // Row for mobile, column for large
                            justifyContent: 'space-between', // Align message and employee details on opposite sides in mobile
                            marginBottom: { xs: '5px', lg: '0' } // Margin for mobile
                          }}>
                            <Typography variant="caption" sx={{
                              color: item.display_msg?.includes('late') ? 'red' : 'green',
                              fontWeight: 500,
                              fontSize: { xs: '0.75rem', lg: '0.9rem' }
                            }}>
                              {item.display_msg?.split(':')[1]?.trim()}
                            </Typography>
                            <Typography sx={{ fontSize: { xs: '0.75rem', lg: '0.9rem' }, color: 'gray' }}>
                              {item.stage} by {item?.completed_by_employee_details?.fullName}
                            </Typography>
                          </Grid>

                          {/* Buttons */}
                          <Grid item xs={12} lg={4} sx={{
                            display: 'flex',
                            flexDirection: { xs: 'row', lg: 'row' }, // Row for both mobile and large
                            justifyContent: { lg: 'flex-end' }, // Right align on large screens
                            gap: '8px',
                            width: '100%'
                          }}>
                            <Button variant="outlined" sx={{
                              textTransform: 'none',
                              fontSize: '0.75rem',
                              padding: '4px 8px',
                              width: { xs: '100%', lg: 'auto' } // Full width button for mobile
                            }} onClick={() => handleUpdateStage("closed", item.item_id, order.order_id)}>
                              Mark Closed
                            </Button>

                            <Button onClick={() => handleButtonClick(order.order_id, item.item_id)} variant="contained" sx={{
                              backgroundColor: 'green',
                              textTransform: 'none',
                              padding: '4px 8px',
                              fontSize: '0.75rem',
                              width: { xs: '100%', lg: 'auto' } // Full width button for mobile
                            }}>
                              Final Image
                            </Button>
                            <input type="file" ref={inputRef} onChange={handleImageChange} style={{ display: "none" }} />
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>




                    <Stack
                      sx={{
                        display: 'flex',
                        flexDirection: { xs: 'row', lg: 'row' }, // Column for mobile, row for large screens
                        alignItems: 'center',
                        gap: { xs: 1, lg: 2 }, // Reduced gap for mobile
                        justifyContent: 'center',
                        marginTop: 2
                      }}
                    >
                      {/* Mark Mistake Button */}
                      {!order.mistake_marked && (
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: 'none',
                            backgroundColor: 'red',
                            padding: '6px 12px', // Adjust padding for smaller buttons
                            fontSize: { xs: '0.7rem', lg: '0.75rem' }, // Slightly smaller font for mobile
                            width: { xs: '100%', lg: 'auto' }, // Full width for mobile, auto for large
                            mt: { xs: 1, lg: 0 }, // Margin for mobile
                          }}
                          onClick={() => markMistakeOnOrderFn(order.order_id)}
                        >
                          Mark Mistake
                        </Button>
                      )}

                      {/* Send Automatic Call Button */}
                      <Button
                        variant="outlined"
                        sx={{
                          textTransform: 'none',
                          fontSize: { xs: '0.7rem', lg: '0.875rem' }, // Reduced font size for mobile
                          padding: '6px 12px', // Adjust padding for smaller buttons
                          width: { xs: '100%', lg: 'auto' }, // Full width for mobile
                          mt: { xs: 1, lg: 0 }, // Margin for mobile
                          whiteSpace: 'nowrap', // Ensure the text stays on one line
                        }}
                        onClick={() => triggerNotificationOnOrderFn(order.order_id)}
                      >
                        Send Automatic Call
                      </Button>
                    </Stack>


                  </Card>
                </Grid>
              ))
            ) : (
              <Box sx={{ width: '100%', height: '100%' }}>
                <Box
                  component="img"
                  src={NoDataImage}
                  alt="Login Image"
                  sx={{ width: '300px', height: '300px', objectFit: 'fill', margin: '0', padding: '0', marginTop: '100px' }}
                />
              </Box>
            )}
          </Grid>
        </Box>

      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5, }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </div>
  );
};

export default CompleteOrder;