import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';


export const fetchDataRequest = () => ({
  type: actionTypes.FETCH_DATA_REQUEST,
});

export const fetchDataSuccess = (data) => ({
  type: actionTypes.FETCH_DATA_SUCCESS,
  payload: data,
});

export const fetchDataFailure = (error) => ({
  type: actionTypes.FETCH_DATA_FAILURE,
  payload: error,
});

export const fetchData = (page, limit,search= '') => {

  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const { data } = await api.charges(page, limit,search);
      dispatch(fetchDataSuccess(data));
      return data;
    } catch (error) {
      dispatch(fetchDataFailure(error));
    }
  };
};

export const createDataRequest = () => ({
  type: actionTypes.CREATE_DATA_REQUEST,
});

export const createDataSuccess = (data) => ({
  type: actionTypes.CREATE_DATA_SUCCESS,
  payload: data,
});

export const createDataFailure = (error) => ({
  type: actionTypes.CREATE_DATA_FAILURE,
  payload: error,
});

export const createData = (newData) => {
  return async (dispatch) => {
    dispatch(createDataRequest());
    try {
      const { data } = await api.createCharges(newData);
      console.log("step1");
      dispatch(createDataSuccess(data));
      console.log("step 2");
      toast.success('Data created successfully');
      return data;
    } catch (error) {
      dispatch(createDataFailure(error));
    }
  };
};

export const updateDataRequest = () => ({
  type: actionTypes.UPDATE_DATA_REQUEST,
});

export const updateDataSuccess = (updatedItem) => ({
  type: actionTypes.UPDATE_DATA_SUCCESS,
  payload: updatedItem,
});

export const updateDataFailure = (error) => ({
  type: actionTypes.UPDATE_DATA_FAILURE,
  payload: error,
});

export const updateData = (id, updatedData) => {
  return async (dispatch) => {
    dispatch(updateDataRequest());
    try {
      const response = await api.updateCharge(id, updatedData);
      const updatedItem = response.data; // Assuming the API returns the updated item
      const successAction = updateDataSuccess(updatedItem);
      dispatch(successAction);
      toast.success('Data updated successfully');
      return successAction;
    } catch (error) {
      const failureAction = updateDataFailure(error.message);
      dispatch(failureAction);
      toast.error('Failed to update data');
      return failureAction;
    }
  };
};


export const deleteDataRequest = () => ({
  type: actionTypes.DELETE_DATA_REQUEST,
});

export const deleteDataSuccess = (id) => ({
  type: actionTypes.DELETE_DATA_SUCCESS,
  payload: id,
});

export const deleteDataFailure = (error) => ({
  type: actionTypes.DELETE_DATA_FAILURE,
  payload: error,
});

export const deleteData = (id) => {
  return async (dispatch) => {
    dispatch(deleteDataRequest());
    try {
      await api.deleteCharge(id);
      dispatch(deleteDataSuccess(id));
      toast.success('Data deleted successfully');
    } catch (error) {
      console.log(error);
      dispatch(deleteDataFailure(error));
      console.log("stap 3");
      toast.error('Failed to delete data');
    }
  };
};



