import { AppBar, Box, Button, Card, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Pagination, Stack, TextField, Toolbar, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClosedOrderData } from '../../redux/actions/closedOrderActions';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import { markMistakeOnOrderAction, uploadOrderImage } from '../../redux/actions/orderActions';
import { useNavigate } from 'react-router-dom';

const CloseOrder = () => {
    const closedOrders = useSelector((state) => state.closedOrder.closedOrders);
    const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const [openFilter, setOpenFilter] = useState(false);
    const limit = 3;

    useEffect(() => {
        const fetchClosedOrder = async () => {
            setLoading(true);
            const response = await dispatch(fetchClosedOrderData(page, limit, search));
            const total = response?.data?.total;
            setTotalPages(Math.ceil(total / limit));
            setLoading(false);
        };
        fetchClosedOrder();
    }, [dispatch, page, limit, search]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const refreshButton = async () => {
        setLoading(true);
        await dispatch(fetchClosedOrderData(page, limit, search));
        setLoading(false);
    };

    const handleOpenFilter = () => {
        setOpenFilter(true);
    };

    const handleCloseFilter = () => {
        setOpenFilter(false);
    };

    const handleSearch = async () => {
        setPage(1);
        setLoading(true);
        await dispatch(fetchClosedOrderData(1, limit, search));
        setLoading(false);
        handleCloseFilter(); // Close filter dialog after search
    };

    const handleClear = async () => {
        setSearch('');
        setPage(1);
        setLoading(true);
        await dispatch(fetchClosedOrderData(1, limit, ''));
        setLoading(false);
        handleCloseFilter(); // Close filter dialog after clearing
    };
    const markMistakeOnOrderFn = async (orderId) => {
        const response = await dispatch(markMistakeOnOrderAction(orderId, {}));
        console.log('response', response);
    }

    const navigate = useNavigate();

    {/*Handle Image*/ }

    const inputRef = useRef(null);
    const [imgItemId, setImgItemId] = useState('');
    const [file, setFile] = useState(null);
    const [imgOrderId, setImgOrderId] = useState('');

    const handleImageChange = useCallback((event) => {
        event.preventDefault();
        const selectedFile = event.target.files[0];
        console.log('filename', selectedFile);
        setFile(selectedFile);
    }, []);
    const handleButtonClick = (orderId, itemId) => {
        setImgOrderId(orderId);
        setImgItemId(itemId);
        console.log(imgOrderId);
        console.log(imgOrderId);
        if (inputRef.current) {
            inputRef.current.click();
        } else {
            console.error("inputRef is null");
        }
    };
    useEffect(() => {
        if (file && imgOrderId && imgItemId) {
            uploadFile(file);
        }
    }, [file, imgOrderId, imgItemId]);

    const uploadFile = useCallback(async (data) => {
        const formData = new FormData();
        formData.append('final_image', data);
        formData.append('item_id', imgItemId);
        formData.append('order_id', imgOrderId);
        await dispatch(uploadOrderImage(formData));

    }, [dispatch, imgItemId, imgOrderId]);



    return (
        <div>
            <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                <Box position="static" sx={{ borderRadius: 3, color: 'black' }}>
                    <Toolbar sx={{ borderRadius: 2 }}>
                        <Typography sx={{ fontSize: '28px' }}>Closed Orders</Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ display: { xs: 'none', md: 'flex', gap: 10 } }}>

                            <IconButton onClick={refreshButton}>
                                <RefreshIcon sx={{ fontSize: '35px' }} />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Box>
                <Container maxWidth="lg">
                    <Grid container rowSpacing={{ lg: 3, md: 2, xs: 1 }} columnSpacing={{ lg: 3, md: 2, xs: 2 }} style={{ marginTop: "2px" }}>
                        {closedOrders && closedOrders.data && closedOrders.data.rows.length > 0 ? (
                            closedOrders.data.rows.map((order, index) => (
                                <Grid item xs={12} md={6} lg={6} key={index} sx={{ padding: '8px' }}>
                                    <Card sx={{ p: 2, boxShadow: '5px 5px rgba(0, 0, 0, 0.19)', borderRadius: '15px' }}>
                                        {/* Order ID */}
                                        <Stack sx={{
                                            display: 'flex',
                                            flexDirection: { xs: 'row', sm: 'row' },
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            width: '100%',
                                            fontSize: { xs: '0.9rem', sm: '1rem' }
                                        }}>
                                            <Typography variant="body2" color="initial" sx={{ fontWeight: 600, fontSize: { xs: '0.725rem' } }}>Order #{order.order_id}</Typography>
                                        </Stack>

                                        {/* Customer Name and Phone */}
                                        <Stack sx={{
                                            display: 'flex',
                                            flexDirection: { xs: 'row', sm: 'row' },
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            width: '100%',
                                            mt: 1,
                                            gap: { xs: '2px', sm: '6px' }, // Reduced gap

                                        }}>
                                            <Typography sx={{ fontSize: '0.9rem' }}>{order.associated_customer.name}</Typography>
                                            <IconButton sx={{ padding: 0 }}>
                                                <LocalPhoneRoundedIcon fontSize="small" />
                                                <Typography marginLeft={1} sx={{ color: 'black', fontSize: '0.9rem' }}>{order.associated_customer.mobile}</Typography>
                                            </IconButton>
                                        </Stack>

                                        {/* Details */}
                                        <Typography variant="body2" align='left' sx={{ color: 'gray', mt: 1, fontSize: { xs: '0.85rem', sm: '1rem' } }}>
                                            Details
                                        </Typography>

                                        {/* Order Items */}
                                        <Grid container sx={{ marginTop: '5px' }}>
                                            {order.order_items && order.order_items.map((item, index) => (
                                                <Grid container item xs={12} lg={12} key={index} sx={{ marginBottom: { xs: 0, lg: 0.5 } }}> {/* Reduced gap */}
                                                    <Grid item xs={6} sm={6} lg={5} sx={{ textAlign: { xs: 'left', sm: 'left' } }}>
                                                        <Typography variant="caption" sx={{
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            fontSize: { xs: '0.75rem', sm: '0.85rem' }
                                                        }}>
                                                            {item.associated_order_product?.product_name || "Product's name is not"}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={3} lg={2} sx={{ textAlign: { xs: 'right', sm: 'left' } }}>
                                                        <Typography variant="caption" sx={{ fontSize: { xs: '0.75rem', sm: '0.85rem' } }}>
                                                            Qty: {item.quantity}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} lg={5} sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        gap: { xs: '4px', sm: '6px' }, // Reduced gap
                                                        justifyContent: { xs: 'space-between', sm: 'center' }
                                                    }}>
                                                        <Button variant='contained' sx={{ height: 20, textTransform: 'none', backgroundColor: 'red' }} onClick={() => markMistakeOnOrderFn(order.order_id)}>Mistake</Button>
                                                        {!item.final_image && (
                                                            <>
                                                                <Button onClick={() => handleButtonClick(order.order_id, item.item_id)} variant="contained" sx={{
                                                                    height: 20,
                                                                    textTransform: 'none',
                                                                    backgroundColor: 'green',
                                                                    padding: '2px 4px',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    maxWidth: '100px',
                                                                    fontSize: { xs: '0.75rem', sm: '0.85rem' }
                                                                }}>
                                                                    Final Image
                                                                </Button>
                                                                <input type="file" ref={inputRef} onChange={handleImageChange} style={{ display: "none" }} />
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>

                                        {/* View Button */}
                                        <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: { xs: 1, lg: 2 } }}>
                                            <Button variant='outlined' sx={{ height: 20, textTransform: 'none', fontSize: { xs: '0.8rem', sm: '1rem' } }} onClick={() => navigate(`/order/view/${order.order_id}`)}>View</Button>
                                        </Stack>
                                    </Card>
                                </Grid>
                            ))
                        ) : (
                            <Typography variant="body1" color="initial">No orders found</Typography>
                        )}
                    </Grid>

                    {/* Pagination */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                        <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
                    </Box>
                </Container>




            </Box>

            <Dialog open={openFilter} onClose={handleCloseFilter}>
                <DialogTitle>Filter Customers</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Search by name, mobile, or address"
                        fullWidth
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        variant="outlined"
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClear} color="primary">
                        Clear
                    </Button>
                    <Button onClick={handleSearch} color="primary">
                        Search
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CloseOrder;
