import React, { useEffect, useState } from 'react';
import {
  Paper, TableContainer, Table, TableHead, TableCell, Button, TableRow, TableBody, Dialog, DialogTitle, DialogContent, DialogActions, Stack,
  TextField, IconButton, Box, Typography, Toolbar, Pagination, CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerData, addCustomerData, editCustomerData, deleteCustomerData } from '../../redux/actions/customerActions';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RefreshIcon from '@mui/icons-material/Refresh';

const CustomerPage = () => {
  const dispatch = useDispatch();
  const { customers, error } = useSelector((state) => state.customer);
  const [loading, setLoading] = useState(true);
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [mobile, setMobile] = useState('');

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const limit = 50;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let rsp = await dispatch(fetchCustomerData(page, limit, search));
      console.log("rsp", rsp);
      let total = rsp?.data?.total;
      let pages = Math.ceil(total / Number(limit));
      setTotalPages(pages);
      setLoading(false);
    };
    fetchData();
  }, [dispatch, page, limit, search]);

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  }
  const handleCloseFilter = () => {
    setOpenFilter(false);
  }

  const handleSearch = async () => {
    setPage(1);
    setLoading(true);
    await dispatch(fetchCustomerData(1, limit, search));
    setLoading(false);
  };

  const handleClear = async () => {
    setSearch('');
    setPage(1);
    setLoading(true);
    await dispatch(fetchCustomerData(1, limit, ''));
    setLoading(false);
  };

  const refreshButton = async () => {
    setLoading(true);
    await dispatch(fetchCustomerData(page, limit, search));
    setLoading(false);
  };

  const handleAddCustomer = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(addCustomerData({ name, address, mobile }));
      setAddOpen(false);
      toast.success(response.message);
      setName('');
      setAddress('');
      setMobile('');
      dispatch(fetchCustomerData(page, limit, search));
    } catch (error) {
      console.error('Failed to add customer', error);
      toast.error('Failed to add customer');
    }
  };

  const handleEditCustomer = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(editCustomerData({ ...currentCustomer, name, address, mobile }));
      setEditOpen(false);
      toast.success('Customer updated successfully!');
      dispatch(fetchCustomerData(page, limit, search));
    } catch (error) {
      console.error('Failed to edit customer', error);
      toast.error('Failed to edit customer');
    }
  };

  const handleDeleteCustomer = async () => {
    try {
      const response = await dispatch(deleteCustomerData(customerToDelete.user_id));
      console.log("response", response);
      toast.success(response.data.message);
      setDeleteOpen(false);

      dispatch(fetchCustomerData(page, limit, search));
    } catch (error) {
      console.error('Failed to delete customer', error);
    }
  };

  const openAddPopup = () => setAddOpen(true);
  const closeAddPopup = () => setAddOpen(false);

  const openEditPopup = (customer) => {
    setCurrentCustomer(customer);
    setName(customer.name);
    setAddress(customer.address);
    setMobile(customer.mobile);
    setEditOpen(true);
  };
  const closeEditPopup = () => setEditOpen(false);

  const openDeletePopup = (customer) => {
    setCustomerToDelete(customer);
    setDeleteOpen(true);
  };
  const closeDeletePopup = () => setDeleteOpen(false);


  const [mobileError, setMobileError] = useState('');
  const checkMobileNumber = () => {
    if (!/^\d{10}$/.test(mobile)) {
      setMobileError('Mobile number must be a 10-digit number');
    } else {
      setMobileError('');
    }
  };

  const isFormValid = /^\d{10}$/.test(mobile);



  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('language') || 'en';
  });
  const headingObj = {
    en: 'Customer',
    hi: 'ग्राहक'
  };
  const addButton = {
    en: 'Add New Customer',
    hi: 'नया ग्राहक जोडे'
  };
  const edit = {
    en: 'Edit',
    hi: 'संशोधित'
  };
  const view = {
    en: 'View',
    hi: 'आलोकन'
  };
  const remove = {
    en: 'Delete',
    hi: 'समाप्त'
  };
  const submit = {
    en: 'Submit',
    hi: 'निवेदन'
  };
  const cancel = {
    en: 'Cancel',
    hi: 'रद्द करें'
  };
  const update = {
    en: 'Update',
    hi: 'संशोधन'
  };
  const removeMsg = {
    hi: 'क्या आप वाकई ग्राहक को हटाना चाहते हैं',
    en: 'Are you sure you want to delete this customer'
  };

  return (
    <div>
      <Box position="static" sx={{ borderRadius: 3, color: 'black' }}>
        <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
          <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            width: '100%'
          }}>
            <Typography sx={{
              fontSize: { xs: '20px', sm: '24px', md: '28px' },
              mb: { xs: 2, md: 0 },

            }}>{headingObj[language]}</Typography>


            <Box sx={{
              display: 'flex',
              gap: { xs: 1, md: 0.5 },
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: { xs: 'center', md: 'flex-end' },
              width: { xs: '100%', md: 'auto' },
              alignItems: 'center'
            }}>
              <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'row', md: 'row' },
                justifyContent: { xs: 'flex-start' }
              }}>
                <IconButton onClick={handleOpenFilter}>
                  <FilterAltIcon sx={{ fontSize: '35px' }} />
                </IconButton>

                <IconButton onClick={refreshButton}>
                  <RefreshIcon sx={{ fontSize: '35px' }} />
                </IconButton>
              </Box>
              <Button variant='contained' 
              sx={{ 
                backgroundColor: 'midnightblue',
                textTransform: 'none',
                fontSize: { xs: '12px', sm: '14px', md: '16px' },
                width: { xs: '100%', sm: 'auto' } 
                }} 
                onClick={openAddPopup}>
                {addButton[language]}
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </Box>
      <Paper sx={{ margin: '1%', padding: '1%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1%' }}>
        </div>
        <div style={{ margin: '1%' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: 'midnightblue' }}>
                  <TableCell style={{ color: 'white' }}>Name</TableCell>
                  <TableCell style={{ color: 'white' }}>Address</TableCell>
                  <TableCell style={{ color: 'white' }}>District</TableCell>
                  <TableCell style={{ color: 'white' }}>Mobile</TableCell>
                  <TableCell style={{ color: 'white' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : error ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      Error fetching data
                    </TableCell>
                  </TableRow>
                ) : customers && customers?.data?.rows?.length > 0 ? (
                  customers.data.rows.map((customer, index) => (
                    <TableRow key={index}>
                      <TableCell>{customer.name}</TableCell>
                      <TableCell>{customer.address}</TableCell>
                      <TableCell>{customer.district}</TableCell>
                      <TableCell>{customer.mobile}</TableCell>
                      <TableCell>
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <Button onClick={() => openEditPopup(customer)} variant='contained' style={{ backgroundColor: 'green', color: 'white', textTransform: 'none' }}>
                            {edit[language]}
                          </Button>
                          <Button onClick={() => openDeletePopup(customer)} variant='contained' style={{ backgroundColor: 'red', color: 'white', textTransform: 'none' }}>
                            {remove[language]}
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No Data Available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1%' }}>
            <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
          </Box>
        </div>
      </Paper>

      {/* Add Customer Dialog */}
      <Dialog open={addOpen} onClose={closeAddPopup}>
        <DialogTitle>Add New Customer</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} fullWidth />
            <TextField label="Address" value={address} onChange={(e) => setAddress(e.target.value)} fullWidth />
            <TextField label="Mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} onBlur={checkMobileNumber} error={!!mobileError} helperText={mobileError} fullWidth />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAddPopup}>{cancel[language]}</Button>
          <Button onClick={handleAddCustomer} disabled={!isFormValid}>{submit[language]} </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Customer Dialog */}
      <Dialog open={editOpen} onClose={closeEditPopup}>
        <DialogTitle>Edit Customer</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} fullWidth />
            <TextField label="Address" value={address} onChange={(e) => setAddress(e.target.value)} fullWidth />
            <TextField label="Mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} onBlur={checkMobileNumber} error={!!mobileError} helperText={mobileError} fullWidth />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditPopup}>{cancel[language]}</Button>
          <Button onClick={handleEditCustomer}>{update[language]}</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Customer Dialog */}
      <Dialog open={deleteOpen} onClose={closeDeletePopup}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          {removeMsg[language]}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeletePopup}>{cancel[language]}</Button>
          <Button onClick={handleDeleteCustomer} color="error">{remove[language]}</Button>
        </DialogActions>
      </Dialog>

      {/* Filter Dialog */}
      <Dialog open={openFilter} onClose={handleCloseFilter}>
        <DialogTitle>Filter Customers</DialogTitle>
        <DialogContent>
          <TextField label="Search by name, mobile, or address" fullWidth value={search} onChange={(e) => setSearch(e.target.value)} variant="outlined" margin="normal" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClear} color="primary">
            Clear
          </Button>
          <Button onClick={handleSearch} color="primary">
            Search
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </div>
  );
};

export default CustomerPage;
