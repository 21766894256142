import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Avatar } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderCountData } from '../../redux/actions/orderCountActions';

function Dashboardgrid() {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.orderCount.data);
    const [loading, setLoading] = useState(true);

    console.log(data);

    useEffect(() => {
        const fetchOrderCount = async () => {
            setLoading(true);
            await dispatch(fetchOrderCountData());
            setLoading(false);
        };
        fetchOrderCount();
    }, [dispatch]);


    return (
        <>
            <Typography variant="h4" color="grey.800">Quick Stats - Today</Typography>
            <Box display="flex" gap={2} width="100%">

                <BoxWrapper  sx={{backgroundColor: 'gray'}}>
                    <Box display="flex" alignItems="center" flexDirection='column' sx={{backgroundColor: 'green', color: 'white'}}>
                        <Typography variant="body1" sx={{ fontSize: '1.6rem' }}>Order Item</Typography>
                        <Typography variant="h1" >{data && data.data && data.data.order_item_for_today}</Typography>
                        <Typography variant="body1"  sx={{ fontWeight: 'bolder' }}>For Today</Typography>
                    </Box>
                </BoxWrapper>

                <BoxWrapper  sx={{backgroundColor: 'gray'}}>
                    <Box display="flex" alignItems="center" flexDirection='column' sx={{backgroundColor: 'gray', color: 'white'}}>
                        <Typography variant="body1" sx={{ fontSize: '1.6rem' }}>Order Item</Typography>
                        <Typography variant="h1" >{data && data.data && data.data.order_item_for_tomorrow}</Typography>
                        <Typography variant="body1"  sx={{ fontWeight: 'bolder' }}>For Tomorrow</Typography>
                    </Box>
                </BoxWrapper>

                <BoxWrapper>
                    <Box display="flex" alignItems="center" flexDirection='column'>
                        <Typography variant="body1" sx={{ fontSize: '1.6rem' }}>Order Item</Typography>
                        <Typography variant="h1" color="green">{data && data.data && data.data.order_item_completed_today}</Typography>
                        <Typography variant="text" color="initial" sx={{ fontWeight: 'bolder' }}>Completed Today</Typography>
                    </Box>
                </BoxWrapper>

                </Box>
                
                <Box display="flex" gap={2} width="100%">

                <BoxWrapper>
                    <Box display="flex" alignItems="center" flexDirection='column'>
                        <Typography variant="body1" sx={{ fontSize: '1.6rem' }}>Order Item</Typography>
                        <Typography variant="h1" color="grey">{data && data.data && data.data.order_item_placed}</Typography>
                        <Typography variant="text" color="initial" sx={{ fontWeight: 'bolder' }}>Active</Typography>
                    </Box>
                </BoxWrapper>

                <BoxWrapper>
                    <Box display="flex" alignItems="center" flexDirection='column'>
                        <Typography variant="body1" sx={{ fontSize: '1.6rem' }}>Order Item</Typography>
                        <Typography variant="h1" color="grey">{data && data.data && data.data.order_item_closed_today}</Typography>
                        <Typography variant="body1" color="initial" sx={{ fontWeight: 'bolder' }}>Closed Today</Typography>
                    </Box>
                </BoxWrapper>

                <BoxWrapper>
                    <Box display="flex" alignItems="center" flexDirection='column'>
                        <Typography variant="body1" sx={{ fontSize: '1.6rem' }}>Order Item</Typography>
                        <Typography variant="h1" color="grey">{data && data.data && data.data.order_item_completed_by_me_today}</Typography>
                        <Typography variant="body1" color="initial" sx={{ fontWeight: 'bolder' }}>Completed By Me Today</Typography>
                    </Box>
                </BoxWrapper>

                
            </Box>

            <Typography variant="h4" color="grey.800">Quick Stats - Other</Typography>
            <Box display="flex" gap={2} width="100%">
                
                <BoxWrapper>
                    <Box display="flex" alignItems="center" flexDirection='column'>
                        <Typography variant="body1" sx={{ fontSize: '1.6rem' }}>Order Item</Typography>
                        <Typography variant="h1" color="grey">{data && data.data && data.data.order_item_completed_yesterday}</Typography>
                        <Typography variant="body1" color="initial" sx={{ fontWeight: 'bolder' }}>Completed Yesterday</Typography>
                    </Box>
                </BoxWrapper>

                <BoxWrapper>
                    <Box display="flex" alignItems="center" flexDirection='column'>
                        <Typography variant="body1" sx={{ fontSize: '1.6rem' }}>Order Item</Typography>
                        <Typography variant="h1" color="red">{data && data.data && data.data.order_item_hold}</Typography>
                        <Typography variant="body1" color="initial" sx={{ fontWeight: 'bolder' }}>Hold</Typography>
                    </Box>
                </BoxWrapper>

                <BoxWrapper>
                    <Box display="flex" alignItems="center" flexDirection='column'>
                        <Typography variant="body1" sx={{ fontSize: '1.6rem' }}>Order Item</Typography>
                        <Typography variant="h1" color="grey">{data && data.data && data.data.order_item_pending}</Typography>
                        <Typography variant="body1" color="initial" sx={{ fontWeight: 'bolder' }}>Pending</Typography>
                    </Box>
                </BoxWrapper>
            </Box>
        </>

    );
}

export default Dashboardgrid;

function BoxWrapper({ children }) {
    return (
        <Paper elevation={3} sx={{ borderRadius: 2, flex: 1, borderColor: 'grey.200', borderStyle: 'solid', borderWidth: 1 }}>
            {children}
        </Paper>
    );
}
