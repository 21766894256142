import * as actionTypes from "../actionTypes.js";

const initialState = {
    udharOrders: [],
    loading: false,
    error: null,
}

const udharOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UDHAR_ORDER_REQUEST: 
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.UDHAR_ORDER_SUCCESS:
            return {
                ...state,
                udharOrders: action.payload,
                loading: false,
                error: null,
            };
        case actionTypes.UDHAR_ORDER_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.MARK_UDHAR_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.MARK_UDHAR_SUCCESS:
            state.udharOrders.data.rows = [state.udharOrders.data.rows, action.payload]
            return {
                ...state,
                udharOrders: state.udharOrders,
                loading: false,
                error: null,
            };
        case actionTypes.MARK_UDHAR_FAILURE:
            return {
                ...state,
                loading: false,
                error: null,
            };
        default:
            return state;
    }
};
export default udharOrderReducer;