import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

{/*export const addOrderDetailsRequest = () => ({
    type: actionTypes.ADD_ORDER_DETAILS_REQUEST,
});
export const addOrderDetailsSuccess = (data) => ({
    type : actionTypes.ADD_ORDER_DETAILS_SUCCESS,
    payload: data
});
export const addOrderDetailsFailure = (error) => ({
    type: actionTypes.ADD_ORDER_DETAILS_FAILURE,
    payload: error,
});

export const addOrderDetailsData = (orderDetailsData) => {
    return async (dispatch) => {
        dispatch(addOrderDetailsRequest());
        try{
            console.log("orderDetails :-", orderDetailsData);
            console.log("Order_id",orderDetailsData[0].order_id);
            const response = await api.addOrderDetails(orderDetailsData[0].order_id,orderDetailsData[0]);
            
            const details = response.orderDetailsData;
            dispatch(addOrderDetailsSuccess(details));
            return details;

        } catch (error) {
            dispatch(addOrderDetailsFailure(error));
        }
    };

}; */}
export const editOrderDetailsRequest = () => ({
    type: actionTypes.EDIT_ORDER_DETAILS_REQUEST,
});
export const editOrderDetailsSuccess = (updatedOrder) => ({
    type: actionTypes.EDIT_ORDER_DETAILS_SUCCESS,
    payload: updatedOrder,
});
export const editOrderDetailsFailure = (error) => ({
    type: actionTypes.EDIT_ORDER_DETAILS_FAILURE,
    payload: error,
});
export const editOrderDetailsData = (orderData) => {
    return async (dispatch) => {
        dispatch(editOrderDetailsRequest());
        try{
            const response = await api.editOrderDetails(orderData.order_id,orderData.order_items[0].item_id,orderData);
            const updatedItem = response.data;
            const successAction = editOrderDetailsSuccess(updatedItem);
            dispatch(successAction);
            return successAction;

        } catch (error) {
            dispatch(editOrderDetailsFailure(error));
        }
    };
};

export const deleteOrderDetailsRequest = () => ({
    type: actionTypes.DELETE_ORDER_DETAILS_REQUEST,
});

export const deleteOrderDetailsSuccess = (itemId) => ({
    type: actionTypes.DELETE_ORDER_DETAILS_SUCCESS,
    payload: itemId ,
});

export const deleteOrderDetailsFailure = (error) => ({
    type: actionTypes.DELETE_ORDER_DETAILS_FAILURE,
    payload: error,
});

export const deleteOrderDetails = (orderData) => {
    return async (dispatch) => {
        dispatch(deleteOrderDetailsRequest());
        try {
            await api.deleteOrderDetails(orderData.order_id,orderData.item_id);
            dispatch(deleteOrderDetailsSuccess(orderData.item_id));
            toast.success("Order details deleted successfully");
        } catch (error) {
            dispatch(deleteOrderDetailsFailure(error.message || 'Failed to delete order details'));
            
        }
    };
};

export const markOrderItemMistakeRequest = () => ({
    type: actionTypes.MARK_ORDER_ITEM_MISTAKE_REQUEST,
});

export const markOrderItemMistakeSuccess = (data) => ({
    type: actionTypes.MARK_ORDER_ITEM_MISTAKE_SUCCESS,
    payload: data
});

export const markOrderItemMistakeFailure = (error) => ({
    type: actionTypes.MARK_ORDER_ITEM_MISTAKE_FAILURE,
    payload: error
});

export const markOrderItemMistakeData = (orderId, itemId) => {
    return async (dispatch) => {
        dispatch(markOrderItemMistakeRequest());
        try {
            const response = await markOrderItemMistakeData(orderId, itemId);
            dispatch(markOrderItemMistakeSuccess(response.data));
            toast.success('Order item mistake marked successfully');
            return response.data;
        } catch (error) {
            dispatch(markOrderItemMistakeFailure(error));
            toast.error('Failed to mark order item mistake');
        }
    };
};

export const createMultipleOrderItemRequest = () => ({
    type: actionTypes.CREATE_MULTIPLE_ITEMS_REQUEST,
});
export const createMultipleOrderItemSuccess = (data) => ({
    type: actionTypes.CREATE_MULTIPLE_ITEMS_SUCCESS,
    payload: data
});
export const createMultipleOrderItemFailure = (error) => ({
    type: actionTypes.CREATE_MULTIPLE_ITEMS_FAILURE,
    payload: error
});

export const createMultipleOrderItemData = (orderData) => {
    console.log("Inside Multiple", orderData);
    return async (dispatch) => {
        dispatch(createMultipleOrderItemRequest());
        try {
            console.log('orderdata',orderData[0]);
            
            const {response} = await api.createMultipleItems(orderData[0].order_id,{"order_items": orderData})
            console.log("response in action", response);
            const details = response.Data;
            console.log("details", details);
            await dispatch(createMultipleOrderItemSuccess(details));
            return details;

        } catch (error) {
            dispatch(createMultipleOrderItemFailure(error));
        }
    };
};
