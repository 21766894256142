import { AppBar, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Toolbar, Typography, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import { useDispatch, useSelector } from 'react-redux';
import { addOrderData, deleteOrderData, editOrderData, fetchOrderData, uploadOrderImage } from '../../redux/actions/orderActions';
import { useNavigate } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import { statusChangeData } from '../../redux/actions/statusChangeActions';
import NoDataImage from "../../assets/images/no_data.jpg";
import { Pagination } from '@mui/material';

const OrderPage = () => {
  {/*Fetch Operation*/ }
  const orders = useSelector((state) => state.order.orders);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 50;



  useEffect(() => {
    fetchOrderData(page, limit);
  }, [page]);

  const handlePageChange = async (event, value) => {
    console.log("value", value);
    await setPage(value);
    let rsp = await dispatch(fetchOrderData(value, limit));
  };

  useEffect(() => {
    const fetchOrder = async () => {
      setLoading(true);
      let rsp = await dispatch(fetchOrderData(page, limit));
      console.log("rsp", rsp);
      let total = rsp?.data?.total;
      let pages = Math.ceil(total / Number(limit));
      setTotalPages(pages);
      // await dispatch(fetchCustomerData());
      setLoading(false);
    };
    fetchOrder();
  }, [dispatch]);


  const refreshButton = async () => {
    setLoading(true);
    await dispatch(fetchOrderData(page, limit));
    // await dispatch(fetchCustomerData());
    setLoading(false);
  }

  {/*Edit functionality*/ }
  {/*Delete Functionality*/ }
  const [deleteOrderId, setDeleteOrderId] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const closeDeletePopup = () => setOpenDelete(false);
  const deleteOrderPopup = useCallback((id) => {
    setDeleteOrderId(id);
    setOpenDelete(true);
  }, []);

  const deleteOrderFn = async () => {
    try {
      await dispatch(deleteOrderData(deleteOrderId));
      setOpenDelete(false);
      await dispatch(fetchOrderData());
    } catch (error) {
      console.error('Error deleting order:', error);
    }
  };

  {/*Handling customer Data*/ }

  const [newCustomer, setNewCustomer] = useState('');
  const [district, setDistrict] = useState('');
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const navigate = useNavigate();



  {/*Action Popup*/ }
  const [openAction, setOpenAction] = useState(false);
  const [selectedOrderItem, setSelectedOrderItem] = useState(null);
  const [imageItemId, setImageItemId] = useState('');
  const [imageOrderId, setImageOrderId] = useState('');

  const closeAction = () => setOpenAction(false);


  const handleAction = (orderid, item) => {
    setSelectedOrderItem(item);
    setImageItemId(item.item_id);
    console.log('item id', imageItemId);
    setImageOrderId(orderid);
    console.log('order id', imageOrderId);
    setOpenAction(true);

  };

  {/*Handle Image*/ }
  const inputRef = useRef(null);
  const [url, setUrl] = useState('');


  const handleImageUploadClick = (event) => {
    const file = event.target.files[0];
    console.log('file', file);
    setUrl(URL.createObjectURL(file));
    uploadImage(file);
  }

  const handleImageButtonClick = () => {
    inputRef.current.click();
  }

  const uploadImage = async (data) => {

    console.log("inside upload Image", data)
    const formData = new FormData();
    formData.append('item_id', imageItemId);
    formData.append('order_id', imageOrderId);
    formData.append('final_image', data);

    console.log("FormData before dispatch:", Array.from(formData.entries()));

    await dispatch(uploadOrderImage(formData));
  }

  const [stage, setStage] = useState('')

  const handleUpdateStage = async (newStage, itemId) => {
    const newStages = { stage: newStage, item_id: itemId, order_id: imageOrderId }
    console.log("stage", newStages);
    await dispatch(statusChangeData(newStages));

    closeAction();
    refreshButton();
  }
  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('language') || 'en';
  });
  const headingObj = {
    en: 'Orders',
    hi: 'ऑर्डर सूची'
  }

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';

    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const formatTimeAndDate = (dateString) => {
    if (!dateString) return 'N/A';

    const date = new Date(dateString);
    const time = date.toLocaleTimeString();
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    return `${day} ${month} ${year} ${time.split(':').slice(0, 2).join(':') + ' ' + time.split(' ')[1]
      }`;
  }

  return (
    <div>
      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <Box position="static" sx={{ borderRadius: 3, color: 'black' }}>
          <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
            <Box sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: { xs: 'flex-start', md: 'space-between' },
              width: '100%'
            }}>
              <Typography sx={{
                fontSize: { xs: '20px', sm: '24px', md: '28px' },
                mb: { xs: 2, md: 0 },
                textAlign: { xs: 'center', md: 'left' }
              }}>
                {headingObj[language]}
              </Typography>

              <Box sx={{
                display: 'flex',
                gap: { xs: 1, md: 0.5 },
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: { xs: 'center', md: 'flex-end' },
                width: { xs: '100%', md: 'auto' },
                alignItems: 'center'
              }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: { xs: 'row', md: 'row' },
                  justifyContent: { xs: 'flex-start' }
                }}>
                  <IconButton onClick={refreshButton}>
                    <RefreshIcon sx={{ fontSize: { xs: '25px', sm: '30px', md: '35px' } }} />
                  </IconButton>
                </Box>

                <Button
                  variant='contained'
                  sx={{
                    backgroundColor: 'midnightblue',
                    textTransform: 'none',
                    fontSize: { xs: '12px', sm: '14px', md: '16px' },
                    width: { xs: '100%', sm: 'auto' }
                  }}
                  onClick={() => navigate('/order/create')}
                >
                  Add New Order
                </Button>
              </Box>
            </Box>
          </Toolbar>
        </Box>

        <Box  >
          <Grid container rowSpacing={{ lg: 3, md: 2, xs: 1 }} columnSpacing={{ lg: 3, md: 2, xs: 2 }} style={{ marginTop: "2px" }}>
            {orders && orders.data && orders.data.rows && orders.data.rows.length > 0 ? (
              orders.data.rows.map((order, index) => (
                <Grid item xs={12} md={6} lg={6} key={index} sx={{ padding: '4px' }}>
                  <Card sx={{ p: 2, boxShadow: '5px 5px rgba(0, 0, 0, 0.10)', borderRadius: '15px', height: '100%', backgroundColor: '#E8E8E8', width: '100%', }}>
                    <Stack spacing={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%'
                    }}>

                      <Tooltip title={"Order added at on " + formatTimeAndDate(order.createdAt) || order.order_id}>
                        <Typography variant="body2" color="initial" sx={{ fontWeight: 600, fontSize: { xs: '0.725rem' } }}>  {order.completion_date ? formatDate(order.completion_date) : 'N/A'} | File Number: {order.fileNumber} | Order #{order.order_id}</Typography>

                      </Tooltip>
                    </Stack>
                    {/* Customer Name and Phone */}
                    <Stack spacing={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%'
                    }}>

                      <Typography sx={{ fontSize: { xs: '0.9rem', lg: '1.7rem' }, color: 'darkgray' }}>{order.associated_customer?.name}</Typography>
                      <IconButton sx={{ padding: 0 }}>
                        <LocalPhoneRoundedIcon fontSize="small" />
                        <Typography marginLeft={1} sx={{ color: 'black', fontSize: { xs: '0.9rem', lg: '1.2rem' } }}>{order.associated_customer?.mobile}</Typography>
                      </IconButton>
                    </Stack>
                    {/* Details */}
                    <Typography variant="body2" align='left' sx={{ color: 'gray', mt: 1, fontSize: { xs: '0.85rem', sm: '1rem' } }}>
                      Details
                    </Typography>

                    {/* Order Items */}
                    <Grid container sx={{ marginTop: '5px' }}>
                      {order.order_items && order.order_items.map((item, index) => (
                        <Grid container item xs={12} lg={12} md={12} key={index} sx={{ marginBottom: { xs: 1, lg: 1 } }}>
                          <Grid item xs={6} md={6} lg={3} sx={{ textAlign: { xs: 'left', sm: 'left' } }}>
                            <Typography variant="caption" sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontSize: { xs: '0.75rem', sm: '0.85rem' },
                              textDecoration: (item.stage === 'design_ready' || item.stage === 'completed') ? 'line-through' : 'none'
                            }}>
                              {item.associated_order_product?.product_name || "Product's name is not"}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} md={6} lg={3} sx={{ textAlign: { xs: 'right', sm: 'left' } }}>
                            <Typography variant="caption" sx={{ fontSize: { xs: '0.75rem', sm: '0.85rem' } }}>
                              Qty: {item.quantity}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} md={6} lg={3} sx={{ textAlign: {xs:'left'} }}>
                            <Box sx={{ 
                              display: 'flex', 
                              flexDirection: 'row', 
                              gap: { xs: '1px', lg: '2px' },
                              
                              }}>
                              <Typography variant="caption" sx={{ 
                                color: item.display_msg.includes('late') ? 'red' : 'green', 
                                fontSize: { xs: '0.75rem', lg: 'inherit' },
                                }}>
                                - {item.display_msg.split(':')[1]?.trim()}
                              </Typography>
                              <Typography sx={{ fontSize: { xs: '0.7rem', lg: '0.8rem' } }}>({item.stage})</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6} md={6} lg={3} sx={{ textAlign: {xs: 'right', lg: 'left'} }}>
                            <Button variant="contained" sx={{ height: 20, textTransform: 'none' }} onClick={() => handleAction(order.order_id, item)}>
                              Action
                            </Button>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                    <Stack spacing={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5, justifyContent: 'center', marginTop: 2 }}>

                      <Button variant='outlined' sx={{ height: 25, textTransform: 'none', marginRight: 3 }} onClick={() => navigate(`view/${order.order_id}`)}>View</Button>
                      <Button variant='outlined' sx={{ height: 25, textTransform: 'none', marginRight: 3 }} onClick={() => navigate(`edit/${order.order_id}`)}>Edit</Button>
                      <Button variant="outlined" color="error" sx={{ height: 25, textTransform: 'none', marginRight: 3 }} onClick={() => deleteOrderPopup(order.order_id)}>Delete</Button>
                    </Stack>
                  </Card>
                </Grid>
              ))
            ) : (
              <Box sx={{ width: '100%', height: '100%' }}>
                <Box
                  component="img"
                  src={NoDataImage}
                  alt="Login Image"
                  sx={{ width: '300px', height: '300px', objectFit: 'fill', margin: '0', padding: '0', marginTop: '100px' }}
                />
              </Box>
            )}
          </Grid>
        </Box>
      </Box>


      <Dialog open={openDelete} onClose={closeDeletePopup}>
        <DialogTitle sx={{ marginLeft: 6 }}>Product Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogActions>
            <Stack spacing={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, justifyContent: 'center' }}>
              <Typography variant="body1" color="initial">Are you sure you want to delete Order</Typography>
              <Stack spacing={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, justifyContent: 'center' }} >
                <Button variant='outlined' sx={{ marginRight: 2 }} onClick={closeDeletePopup}>Cancel</Button>
                <Button variant='contained' sx={{ marginRight: 2, backgroundColor: 'red' }} onClick={deleteOrderFn}>Delete</Button>
              </Stack>
            </Stack>
          </DialogActions>
        </DialogContent>
      </Dialog>


      {/*Action Popup*/}
      <Dialog open={openAction} onClose={closeAction} >

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
            <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Order Item Action</Typography>
          </DialogTitle>
          <IconButton onClick={closeAction} >

          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <DialogContent>
            {console.log(selectedOrderItem)}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
              {selectedOrderItem && (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography>Your Current Order Item </Typography>
                  <Typography>
                    <Typography component="span" sx={{ color: 'purple', marginRight: 1, fontWeight: 'bold' }}>{selectedOrderItem && selectedOrderItem.associated_order_product && selectedOrderItem.associated_order_product.product_name}
                    </Typography>
                    is
                  </Typography>
                  <Typography variant='h5' sx={{ color: 'green' }}>{selectedOrderItem.stage}</Typography>
                </Box>
              )}

              <div onClick={handleImageButtonClick}>
                <Button variant='contained' sx={{ width: '400px', height: '30px', fontSize: '12px' }}>Upload Final Image</Button>
                <input type="file" ref={inputRef} onChange={handleImageUploadClick} style={{ display: "none" }} />
              </div>
            </Box>
          </DialogContent>
          <DialogActions>
            {selectedOrderItem && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>

                <Typography>-------------- </Typography>


                <Button
                  variant='outlined'
                  sx={{ width: '400px', height: '30px', fontSize: '0.9rem', marginLeft: 1, color: 'black' }}
                  onClick={() => handleUpdateStage("design_ready", selectedOrderItem.item_id)}
                >
                  Mark as Design Ready
                </Button>

                <Button
                  variant='contained'
                  sx={{ width: '400px', height: '30px', fontSize: '0.9rem', marginLeft: 1, backgroundColor: 'green' }}
                  onClick={() => handleUpdateStage("completed", selectedOrderItem.item_id)}
                >
                  Mark Order as Completed
                </Button>

                <Button
                  variant='outlined'
                  sx={{ width: '400px', height: '30px', fontSize: '0.9rem', marginLeft: 1, color: 'red' }}
                  onClick={() => handleUpdateStage("hold", selectedOrderItem.item_id)}
                >
                  Mark Order as HOLD
                </Button>

                <Typography>-------------- </Typography>

                <Button
                  variant='outlined'
                  sx={{ width: '400px', height: '30px', fontSize: '0.9rem', marginLeft: 1, color: 'gray' }}
                  onClick={() => handleUpdateStage("closed", selectedOrderItem.item_id)}
                >
                  Directly Mark Order as CLOSED
                </Button>
              </Box>
            )}
          </DialogActions>
        </Box>



      </Dialog>

      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5, }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </div>
  );
};

export default OrderPage;