
import { products } from "../../api/index.js";
import * as actionTypes from "../actionTypes.js";

const initialState = {
    products: [],
    loading: false,
    error: null,

};
const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };

        case actionTypes.FETCH_PRODUCT_SUCCESS:
            return {
                ...state,
                products: action.payload,
                loading: false,
                error: null,
            };

        case actionTypes.FETCH_PRODUCT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.ADD_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.ADD_PRODUCT_SUCCESS:
            state.products.data.rows = [...state.customers.data.rows, action.payload];
            console.log("step2");
            console.log(state)

            return {
                ...state,
                products: state.products,
                loading: false,
                error: null,
            };
        case actionTypes.ADD_PRODUCT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.UPLOAD_PRODUCTIMG_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.UPLOAD_PRODUCTIMG_SUCCESS:
            state.products.data.rows = state.products.data.rows.map(product =>
                product.product_id === action.payload.product_id ? action.payload : product
            )

            return {
                ...state,
                products: state.products,
                loading: false,
                error: null,
            };
        case actionTypes.UPLOAD_PRODUCTIMG_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.EDIT_PRODUCT_REQUEST:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case actionTypes.EDIT_PRODUCT_SUCCESS:
            state.products.data.rows = state.products.data.rows.map(product =>
                product.product_id === action.payload.product_id ? action.payload : product
            )
            return {
                ...state,
                products: state.products,
                loading: false,
                error: null,
            };
        case actionTypes.EDIT_PRODUCT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case actionTypes.DELETE_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.DELETE_PRODUCT_SUCCESS:
            state.products.data.rows = state.products.data.rows.filter(product => product.product_id !== action.payload)
            console.log(action.payload)
            console.log()
            return {
                ...state,
                products: state.products,
                loading: false,
                error: null,
            };
        case actionTypes.DELETE_PRODUCT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.FETCH_PRODUCTLIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };

        case actionTypes.FETCH_PRODUCTLIST_SUCCESS:
            return {
                ...state,
                products: action.payload,
                loading: false,
                error: null,
            };

        case actionTypes.FETCH_PRODUCTLIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default productReducer;
