import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select,
  Stack, TextField, Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getSingleOrderData, editOrderData, fetchOrderData, editMultipleOrderItemData
} from '../../redux/actions/orderActions';
import { fetchProductListData } from '../../redux/actions/productActions';
import CloseIcon from '@mui/icons-material/Close';
import { deleteOrderDetails } from '../../redux/actions/orderDetailsActions';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const EditIndividualOrderPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(params.child);
  const dispatch = useDispatch();

  const orders = useSelector((state) => state.order.orders);
  const products = useSelector((state) => state.product.products);

  useEffect(() => {
    setId(params.child);
  }, [params.child]);

  useEffect(() => {
    const fetchSingleOrder = async () => {
      setLoading(true);
      const orderid = { order_id: id };
      await dispatch(getSingleOrderData(orderid));
      await dispatch(fetchProductListData());
      setLoading(false);
    };

    fetchSingleOrder();
  }, [dispatch, id]);

  const [mobileNumber, setMobilenumber] = useState(orders?.data?.associated_customer?.mobile || '');
  const [orderNumber, setOrderNumber] = useState(orders?.data?.order_id || '');
  const [completionDate, setCompletionDate] = useState(dayjs(orders?.data?.completion_date || null));
  const [fileNumber, setFileNumber] = useState(orders?.data?.file_number);
  const [heading, setHeading] = useState(orders?.data?.heading);
  const [comment, setComment] = useState(orders?.data?.comment);
  const [orderItems, setOrderItems] = useState([]);

  useEffect(() => {
    if (orders?.data) {
      setOrderItems(
        orders.data?.order_items?.map((item) => ({
          ...item,
          completion_date: dayjs(item.completion_date || null),
        }))
      );

      setFileNumber(orders?.data.file_number);
      setHeading(orders?.data.heading);
      setComment(orders?.data.comment);
      setMobilenumber(orders?.data?.associated_customer?.mobile);
      setOrderNumber(orders?.data.order_id);
      setCompletionDate(dayjs(orders?.data.completion_date || null));
    }
  }, [orders]);

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...orderItems];
    updatedItems[index] = {
      ...updatedItems[index],
      [field]: field === 'completion_date' ? dayjs(value || null) : value,
    };
    setOrderItems(updatedItems);
  };

  const handleSave = async () => {
    const orderData = {
      order_items: orderItems,
      file_number: fileNumber,
      heading,
      completion_date: completionDate.format(), // Ensure date is properly formatted
      comment
    };

    try {
      await dispatch(editMultipleOrderItemData(orderData));
      navigate('/order');
    } catch (error) {
      console.error("Failed to save order:", error);
    }
  };

  const handleCancel = () => {
    navigate('/order');
  };

  const handleAddItems = () => {
    navigate(`/order/items/${id}/create`);
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const handleDeleteIconClick = (itemId) => {
    setSelectedItemId(itemId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    const orderData = { order_id: id, item_id: selectedItemId };
    try {
      await dispatch(deleteOrderDetails(orderData));
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Failed to delete order item:", error);
    }
  };

  return (
    <div>
      <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      

        {orderNumber != '' ? (
          <Box sx={{
            display: 'flex', flexDirection: 'column', gap: 2, boxShadow: "0px 4px 8px rgba(0, 0.2, 0.2, 0.4)",
            padding: '20px', borderRadius: '16px', marginBottom: '20px', background: 'white'
          }}>
            <Typography variant='span' sx={{ textAlign: 'right', fontSize: '0.775rem', color: 'gray' }}>Order Id :-{id}</Typography>
            <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: 'full' }}>
              <TextField label="Customer Mobile Number" value={mobileNumber} onChange={(e) => setMobilenumber(e.target.value)} fullWidth />
              <TextField label="Order Number" value={orderNumber} fullWidth />
            </Stack>
            <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Completion Date"
                  value={completionDate}
                  onChange={(newDate) => setCompletionDate(dayjs(newDate || null))}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth sx={{ flexGrow: 1 }} />
                  )}
                />
              </LocalizationProvider>
              {/* <TextField label="Amount" fullWidth sx={{ flexGrow: 1 }} /> */}
            </Stack>
  
          </Box>
  
        ): (
          <Typography>Waiting for Customer Information</Typography>
        )}
        
        <Typography>Edit order item</Typography>

        {orderItems && orderItems.length > 0 ? (
          orderItems.map((item, index) => (
            <Box key={index} sx={{
              display: 'flex', flexDirection: 'column', gap: 2, padding: '20px', background: 'white',
              borderRadius: '16px', marginBottom: '20px', position: 'relative', boxShadow: "0px 4px 8px rgba(0, 0.2, 0.2, 0.4)"
            }}>
              <Typography variant='span' sx={{ textAlign: 'right', fontSize: '0.775rem', color: 'gray' }}>Item Id :-{item.item_id}</Typography>

              <IconButton sx={{
                position: 'absolute', top: '-20px', right: '-10px', backgroundColor: 'white',
                borderRadius: '50%'
              }}
                onClick={() => handleDeleteIconClick(item.item_id)}
              >
                <CloseIcon />
              </IconButton>
              <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%' }}>
                <FormControl sx={{ flex: 1 }}>
                  <InputLabel id={`product-select-label-${index}`}>Product Choose</InputLabel>
                  <Select
                    labelId={`product-select-label-${index}`}
                    id={`product-select-${index}`}
                    value={item.product_id}
                    onChange={(e) => handleItemChange(index, 'product_id', e.target.value)}
                  >
                    {Array.isArray(products?.data) && products.data.map((product) => (
                      <MenuItem key={product.product_id} value={product.product_id}>
                        {product.product_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField label="Quantity" sx={{ flex: 1 }} value={item.quantity} onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}/>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Completion Date"
                    value={dayjs(item.completion_date || null)}
                    onChange={(newDate) => handleItemChange(index, 'completion_date', newDate)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    sx={{ flex: 1 }}
                  />
                </LocalizationProvider>
              </Stack>

              <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: 'full' }}>
                <TextField label="Width" fullWidth value={item.width} onChange={(e) => handleItemChange(index, 'width', e.target.value)} />
                <TextField label="Height" fullWidth value={item.height} onChange={(e) => handleItemChange(index, 'height', e.target.value)} />
              </Stack>
              <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: 'full' }}>
                <TextField label="Any Comments" fullWidth value={item.comments} onChange={(e) => handleItemChange(index, 'comments', e.target.value)} />
              </Stack>
            </Box>
          ))
        ) : (
          <Typography>No order items found</Typography>
        )}
        <Box>
          <Button variant='contained' onClick={handleAddItems}>Add More Items</Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 2 }}>
          <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
        </Box>
      </Stack>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>{"Are you sure you want to delete this order?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deleting this order will remove it permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditIndividualOrderPage;
