import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify'; 

export const fetchClosedOrderRequest = () => ({
    type: actionTypes.CLOSED_ORDER_REQUEST,
});
export const fetchClosedOrderSuccess = (data) => ({
    type : actionTypes.CLOSED_ORDER_SUCCESS,
    payload: data
});
export const fetchClosedOrderFailure = (error) => ({
    type: actionTypes.CLOSED_ORDER_FAILURE,
    payload: error,
});

export const fetchClosedOrderData = (page, limit, search = '') => {
    return async (dispatch) => {
        dispatch(fetchClosedOrderRequest());
        try{
            const {data} = await api.closedOrder(page, limit, search);
            dispatch(fetchClosedOrderSuccess(data));
            return data;

        } catch (error) {
            dispatch(fetchClosedOrderFailure(error));
        }
    };

};