import './App.css';
import Router from "./routes/router";
import { theme } from "./theme/MuiTheme";
import { ThemeProvider } from "@mui/material/styles";

function App() {
  return (
    <ThemeProvider theme={theme} >
      <Router />
    </ThemeProvider>
  );
}

export default App;