import * as actionTypes from "../actionTypes.js";

const initialState = {
    data: null,
    loading: false,
    error: null,
};

const orderCountReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ORDER_COUNT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_ORDER_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case actionTypes.FETCH_CUSTOMER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default orderCountReducer;