import { orders } from "../../api/index.js";
import * as actionTypes from "../actionTypes.js";

const initialState = {
    orders: [],
    loading: false,
    error: null,
}

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_ORDER_SUCCESS:
            return {
                ...state,
                orders: action.payload,
                loading: false,
                error: null,
            };
        case actionTypes.FETCH_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.ADD_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.ADD_ORDER_SUCCESS:
            state.orders.data.rows = [...state.orders.data.rows, action.payload]
            console.log("Inside payload")
            console.log(action.payload)
            return {
                ...state,
                orders: state.orders,
                loading: false,
                error: null,
            };
        case actionTypes.ADD_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: null,
            };

        case actionTypes.EDIT_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.EDIT_ORDER_SUCCESS:
            state.orders.data.rows = state.orders.data.rows.map(order =>
                order.order_id === action.payload.order_id ? action.payload : order
            )
            return {
                ...state,
                orders: state.orders,
                loading: false,
                error: null,
            }
        case actionTypes.EDIT_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case actionTypes.EDIT_MULTIPLE_ITEMS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.EDIT_MULTIPLE_ITEMS_SUCCESS:
            state.orders.data.rows = state.orders.data.rows.map(order =>
                order.order_id === action.payload.order_id ? action.payload : order
            )
            return {
                ...state,
                orders: state.orders,
                loading: false,
                error: null,
            }
        case actionTypes.EDIT_MULTIPLE_ITEMS_FAILURE: 
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case actionTypes.DELETE_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.DELETE_ORDER_SUCCESS:
            state.orders.data.rows = state.orders.data.rows.filter(order => order.order_id !== action.payload);
            return {
                ...state,
                orders: state.orders,
                loading: false,
                error: null,
            };
        case actionTypes.DELETE_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.UPLOAD_ORDERIMG_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.UPLOAD_ORDERIMG_SUCCESS:
            state.orders.data.rows.order_items = state.orders.data.rows.order_items.map(orderDetails =>
                orders.item_id === action.payload.item_id ? action.payload : orderDetails
            )
            return {
                ...state,
                orders: state.orders,
                loading: false,
                error: null,
            };
        case actionTypes.GET_SINGLE_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case actionTypes.GET_SINGLE_ORDER_SUCCESS:
            return {
                ...state,
                orders: action.payload,
                loading: false,
                error: null,
            }
        case actionTypes.GET_SINGLE_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }



        default:
            return state;
    }
};
export default orderReducer;